import React from 'react'
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import HeaderComponent from "./Header";

const { Content } = Layout;

const Index = ({ children }) => {

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout>
        <HeaderComponent />
        <Content style={{ margin: "24px 16px 0" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            {location.pathname.split("/").map((path) => {
              return <Breadcrumb.Item>{path}</Breadcrumb.Item>;
            })}
          </Breadcrumb> */}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Index;
