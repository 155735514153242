import React from 'react'
import { useContext, useState, useCallback, useEffect } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { Input, Button, Row, Col, Form, Spin, DatePicker, Select, Divider } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import defaultLogoKemendikbud from '../../../Assets/kemendikbud.png'
import debounce from 'lodash.debounce'
import API from '../../../Services/API'

import ReactSelect from 'react-select/creatable'

const { TextArea } = Input

const { RangePicker } = DatePicker
const { Option } = Select

const rangeConfig = {
	rules: [
		{
			type: 'array',
			required: true,
			message: 'Please select time!',
		},
	],
}

const StepOne = () => {
	const authContext = useContext(AuthContext)
	const [countryData, setCountryData] = useState([])
	const [fetching, setFetching] = useState(false)
	const [loading, setLoading] = useState(false)
	const [cooperationDirector, setCooperationDirector] = useState([])
	const [listCompany, setListCompany] = useState({ isLoading: true, data: [] })
	const [listCompanyKeyword, setListCompanyKeyword] = useState('')

	const fetchCountry = useCallback(
		debounce(async value => {
			if (!fetching) {
				setFetching(true)
				const res = await API.getCountryData({ keyword: value })
				setCountryData(res.data.list)
				setFetching(false)
			}
		}, 500)
	)

	const fetchCooperationDirector = async () => {
		if (!loading) {
			setLoading(true)
			const res = await API.getCooperationDirector()
			setCooperationDirector(
				res.data.list.map(cDirector => ({
					label: cDirector.name,
					value: cDirector.name,
				}))
			)
			setLoading(false)
		}
	}

	const searchCompany = useCallback(
		debounce(async value => {
			setListCompany(prev => ({ ...prev, isLoading: true }))
			try {
				const response = await API.getCompany({ keyword: value })
				setListCompany({
					data: response.data.list,
					isLoading: false,
				})
			} catch (error) {
				setListCompany(prev => ({ ...prev, isLoading: false }))
			}
		}, 500),
		[listCompanyKeyword]
	)

	const fetchCompany = async value => {
		setListCompany(prev => ({ ...prev, isLoading: true }))
		try {
			const response = await API.getCompany({ keyword: value })
			setListCompany({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			setListCompany(prev => ({ ...prev, isLoading: false }))
		}
	}

	useEffect(() => {
		fetchCompany()
	}, [])

	useEffect(() => {
		fetchCountry('')
		fetchCooperationDirector()
	}, [])


	return (
		<Row>
			<Col
				xs={24}
				// sm={24}
				md={8}
				lg={8}
				style={{ marginRight: 50 }}>
				<Form layout='vertical' size='small'>
					<Form.Item label='Jenis Document'>
						<Select
							// defaultValue={authContext.contractDataInput.type}
							style={{ width: 120 }}
							style={{ marginRight: 10 }}
							value={authContext.contractDataInput.type}
							onChange={value => {
								authContext.updateContractDataInput({ type: value })
							}}>
							<Option value={null} disabled>
								--Pilih Jenis Document--
							</Option>
							<Option value={1}>MoU / Nota Kesepahaman</Option>
							<Option value={2}>PKS</Option>
						</Select>
					</Form.Item>

					<Form.Item label='Klasifikasi'>
						<Select
							// defaultValue={authContext.contractDataInput.type}
							style={{ width: 120 }}
							style={{ marginRight: 10 }}
							value={authContext.contractDataInput.classificationType}
							onChange={value => {
								authContext.updateContractDataInput({ classificationType: value })
							}}>
							<Option value={null} disabled>
								--Pilih Klasifikasi--
							</Option>
							<Option value={1}>PTVP</Option>
							<Option value={2}>SMK</Option>
							<Option value={3}>SUSLAT</Option>
							<Option value={4}>TU</Option>
						</Select>
					</Form.Item>

					<Form.Item label='Nama Perjanjian'>
						<Input
							placeholder='Nama Perjanjian'
							value={authContext.contractDataInput.name}
							onChange={({ target: { value } }) => authContext.updateContractDataInput({ name: value })}
						/>
					</Form.Item>

					<Form.Item label='Pembina Kerjasama'>
						{/* <Input
							placeholder='Pembina Kerjasama'
							value={authContext.contractDataInput.cooperationDirector}
							onChange={({ target: { value } }) => authContext.updateContractDataInput({ cooperationDirector: value })}
						/> */}
						<ReactSelect
							isLoading={loading}
							placeholder='Pembina Kerjasama'
							isMulti={false}
							options={cooperationDirector}
							onChange={event => {
								authContext.updateContractDataInput({ cooperationDirector: event.value })
							}}
						/>
					</Form.Item>

					<Form.Item label='Tempat Perjanjian'>
						<Input
							placeholder='Tempat'
							value={authContext.contractDataInput.location}
							onChange={({ target: { value } }) => authContext.updateContractDataInput({ location: value })}
						/>
					</Form.Item>

					<Form.Item label='Tanggal Kerja Sama' {...rangeConfig}>
						<RangePicker
							style={{ width: '100%' }}
							value={[
								moment(authContext.contractDataInput.contractStart, 'YYYY-MM-DD'),
								moment(authContext.contractDataInput.contractEnd, 'YYYY-MM-DD'),
							]}
							onChange={date =>
								authContext.updateContractDataInput({
									contractStart: date[0].format('YYYY-MM-DD'),
									contractEnd: date[1].format('YYYY-MM-DD'),
								})
							}
						/>
					</Form.Item>

					<p style={{ color: 'red', fontSize: 12 }}>{authContext.errorMessage.contract}</p>
				</Form>
			</Col>
			<Col
				xs={24}
				// sm={24}
				md={8}
				lg={8}>
				<Form layout='vertical' size='small'>
					<Form.Item label='Pilih Institusi'>
						<Select
							loading={listCompany.isLoading}
							filterOption={false}
							showSearch
							onSearch={searchCompany}
							style={{ width: 120 }}
							style={{ marginRight: 10 }}
							value={authContext.defaultInstitusi}
							onChange={(value, opt) => {
								searchCompany('')
								if (value === 1 || value === 2 || value === null) {
									authContext.changeDefaultInstitusi(value)
								} else {
									const companyValue = JSON.parse(value)
									authContext.changeDefaultInstitusi(value)

									authContext.updateDataInputFirstInstitusi({
										companyName: companyValue.name,
										companyAddress: companyValue.address,
										companyAbout: companyValue.latestAbout ? companyValue.latestAbout.about : '',
										companyLogo: companyValue.urlLogo,
										officerName:
											companyValue.companySigners.length > 0 ? companyValue.companySigners[0].officerName : '',
										officerPosition:
											companyValue.companySigners.length > 0 ? companyValue.companySigners[0].officerPosition : '',
										countryID: companyValue.countryID,
									})
								}
							}}>
							<Option value={null} disabled>
								--Pilih Institusi--
							</Option>
							<Option value={2}>Ketik Sendiri</Option>
							{listCompany.data.map(company => (
								<Option value={JSON.stringify(company)} key={company.ID}>
									{company.name}
								</Option>
							))}
						</Select>
					</Form.Item>

					{authContext.defaultInstitusi === null ? null : (
						<Form.Item label='Institusi / Pihak Diksi'>
							<Input
								disabled={authContext.defaultInstitusi === 1 || typeof authContext.defaultInstitusi === 'string'}
								placeholder='Nama Institusi / Pihak Diksi'
								style={{ marginBottom: 10 }}
								value={authContext.firstInstitusi.companyName}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputFirstInstitusi({
										companyName: value,
									})
								}}
							/>

							<TextArea
								disabled={authContext.defaultInstitusi === 1 || typeof authContext.defaultInstitusi === 'string'}
								placeholder='Alamat'
								style={{ marginBottom: 10 }}
								value={authContext.firstInstitusi.companyAddress}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputFirstInstitusi({
										companyAddress: value,
									})
								}}></TextArea>
							<Select
								disabled={authContext.defaultInstitusi === 1 || typeof authContext.defaultInstitusi === 'string'}
								showSearch
								value={(authContext.defaultInstitusi === 1 || typeof authContext.defaultInstitusi === 'string') ? 64 : authContext.firstInstitusi.countryID}
								placeholder='Pilih Negara'
								notFoundContent={fetching ? <Spin size='small' /> : null}
								filterOption={false}
								onSearch={fetchCountry}
								onChange={value => {
									setFetching(false)
									authContext.updateDataInputFirstInstitusi({
										countryID: value,
									})
								}}
								style={{ marginBottom: 10 }}>
								{countryData.map(d => {
									return (
										<Option key={d.ID} value={d.ID}>
											{d.name}
										</Option>
									)
								})}
							</Select>
							<TextArea
								placeholder='About'
								style={{ marginBottom: 10 }}
								value={authContext.firstInstitusi.companyAbout}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputFirstInstitusi({
										companyAbout: value,
									})
								}}></TextArea>
							<Input
								placeholder='Officer Name / PIC'
								style={{ marginBottom: 10 }}
								value={authContext.firstInstitusi.officerName}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputFirstInstitusi({
										officerName: value,
									})
								}}
							/>
							<Input
								placeholder='Jabatan '
								style={{ marginBottom: 10 }}
								value={authContext.firstInstitusi.officerPosition}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputFirstInstitusi({
										officerPosition: value,
									})
								}}
							/>

							{typeof authContext.defaultInstitusi === 'string' ? (
								<>
									<p>Logo :</p>

									<img
										src={authContext.firstInstitusi.companyLogo}
										alt='Logo'
										style={{ height: '200px', width: '200px' }}
									/>
								</>
							) : (
								<>
									<p>Logo :</p>
									<input
										type='file'
										name='firstFile'
										onChange={({ target: { files } }) => {
											authContext.updateDataInputFirstInstitusi({
												companyLogo: files[0],
											})
										}}
									/>
									<span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
									<p style={{ color: 'red', fontSize: 12 }}>
										{(authContext.errorMessage.assignFirst ? authContext.errorMessage.assignFirst.companyLogo : null) || ''}
									</p>
								</>
							)}
						</Form.Item>
					)}

					{/* ============================================================================================ */}
					<Divider />

					<Form.Item label='Pilih Partner'>
						<Select
							loading={listCompany.isLoading}
							filterOption={false}
							showSearch
							onSearch={searchCompany}
							style={{ width: 120 }}
							style={{ marginRight: 10 }}
							value={authContext.defaultInstitusiLast}
							onChange={(value, opt) => {
								searchCompany('')
								if (value === 1 || value === null) {
									authContext.setDefaultInstitusiLast(value)
									authContext.updateDataInputLastInstitusi({
										companyName: null,
										companyAddress: null,
										companyAbout: null,
										companyLogo: null,
										officerName: null,
										officerPosition: null,
										contractNumber: null,
										companyType: null,
										countryID: null,
									})
								} else {
									const companyValue = JSON.parse(value)
									authContext.setDefaultInstitusiLast(value)

									authContext.updateDataInputLastInstitusi({
										companyName: companyValue.name,
										companyAddress: companyValue.address,
										companyAbout: companyValue.latestAbout && companyValue.latestAbout.about ? companyValue.latestAbout.about : '',
										companyLogo: companyValue.urlLogo,
										officerName:
											companyValue.companySigners.length > 0 ? companyValue.companySigners[0].officerName : '',
										officerPosition:
											companyValue.companySigners.length > 0 ? companyValue.companySigners[0].officerPosition : '',
										countryID: companyValue.countryID,
									})
								}
							}}>
							<Option value={null} disabled>
								--Pilih Partner--
							</Option>
							<Option value={1}>Ketik Sendiri</Option>
							{listCompany.data.map(company => (
								<Option value={JSON.stringify(company)} key={company.ID}>
									{company.name}
								</Option>
							))}
						</Select>
					</Form.Item>

					{authContext.defaultInstitusiLast === null ? null : (
						<Form.Item label='Partner'>
							<Input
								disabled={typeof authContext.defaultInstitusiLast === 'string'}
								placeholder='Nama Partner'
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.companyName}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputLastInstitusi({
										companyName: value,
									})
								}}
							/>

							<Select
								// defaultValue={authContext.contractDataInput.type}
								style={{ width: 120 }}
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.companyType}
								onChange={value => {
									authContext.updateDataInputLastInstitusi({ companyType: value })
								}}>
								<Option value={null} disabled>
									--Pilih Jenis Partner Kerjasama--
								</Option>
								<Option value={1}>DUDI Dalam Negeri</Option>
								<Option value={2}>Institusi Dalam Negeri</Option>
								<Option value={3}>Institusi Luar Negeri</Option>
								<Option value={4}>Organisasi Dalam Negeri</Option>
							</Select>

							<TextArea
								disabled={typeof authContext.defaultInstitusiLast === 'string'}
								placeholder='Alamat'
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.companyAddress}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputLastInstitusi({
										companyAddress: value,
									})
								}}></TextArea>

							{/* {MISSING} */}

							<Select
								disabled={typeof authContext.defaultInstitusiLast === 'string'}
								showSearch
								value={authContext.lastInstitusi.countryID}
								placeholder='Pilih Negara'
								notFoundContent={fetching ? <Spin size='small' /> : null}
								filterOption={false}
								onSearch={fetchCountry}
								onChange={value => {
									setFetching(false)
									authContext.updateDataInputLastInstitusi({
										countryID: value,
									})
								}}
								style={{ marginBottom: 10 }}>
								{countryData.map(d => {
									return (
										<Option key={d.ID} value={d.ID}>
											{d.name}
										</Option>
									)
								})}
							</Select>

							<TextArea
								placeholder='About'
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.companyAbout}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputLastInstitusi({
										companyAbout: value,
									})
								}}></TextArea>
							<Input
								placeholder='Officer Name / PIC'
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.officerName}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputLastInstitusi({
										officerName: value,
									})
								}}
							/>
							<Input
								placeholder='Jabatan '
								style={{ marginBottom: 10 }}
								value={authContext.lastInstitusi.officerPosition}
								onChange={({ target: { value } }) => {
									authContext.updateDataInputLastInstitusi({
										officerPosition: value,
									})
								}}
							/>

							<p>Logo :</p>
							<input
								type='file'
								name='lastFile'
								onChange={({ target: { files } }) => {
									authContext.updateDataInputLastInstitusi({
										companyLogo: files[0],
									})
								}}
							/>

							<span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
							<p style={{ color: 'red', fontSize: 12 }}>
								{(authContext.errorMessage.assignSecound ? authContext.errorMessage.assignSecound.companyLogo : null) || ''}
							
							</p>
						</Form.Item>
					)}
				</Form>
			</Col>
		</Row>
	)
}

export default StepOne
