import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { Input, Button, Form, Divider, Modal, List } from 'antd'
import API from '../../../Services/API'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import EmptyState from './EmptyState'
import { FileAddOutlined, DeleteOutlined, AudioOutlined, FolderAddOutlined } from '@ant-design/icons'

const Quill = ReactQuill.Quill
let Font = Quill.import('formats/font')
Font.whitelist = ['TimesNewRoman', 'Arial']
Quill.register(Font, true)

const { TextArea, Search } = Input

const StepTwo = () => {
	const authContext = useContext(AuthContext)
	const [isModalVisibleClauseGroup, setIsModalVisibleGroup] = useState(null)
	const [isModalVisibleClauseItem, setIsModalVisibleItem] = useState(null)
	const [modalSetClauseVisible, setModalSetClauseVisible] = useState(false)

	const [keyword, setKeyword] = useState(null)
	const [pasal, setPasal] = useState({
		isLoading: true,
		items: [],
	})

	const [keywordTemplate, setKeywordTemplate] = useState('')
	const [template, setTemplate] = useState({
		isLoading: true,
		items: [],
	})

	const onSearch = value => setKeyword(value.target.value)

	const fetchListPasal = () => {
		API.listClauseGroup({ keyword }).then(response => {
			if (response.code === 200) {
				setPasal({
					isLoading: false,
					items: response.data.list,
				})
			}
		})
	}

	useEffect(() => {
		fetchListPasal()
	}, [keyword])

	const fetchTemplate = () => {
		API.setOfTemplate({ keyword: keywordTemplate }).then(response => {
			if (response.code === 200) {
				setTemplate({
					isLoading: false,
					items: response.data.list,
				})
			}
		})
	}

	useEffect(() => {
		fetchTemplate()
	}, [keywordTemplate])

	const modules = {
		toolbar: [
			[{ font: Font.whitelist }],
			['bold', 'italic', 'underline'],
			[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
		],
	}

	const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent']

	return (
		<>
			<div className='templateChoiceParent'>
				<Button icon={<FileAddOutlined />} size='small' onClick={() => setModalSetClauseVisible(true)}>
					Pilih template perjanjian
				</Button>
			</div>
			<Form layout='vertical' size='small'>
				<Form.Item
					// label="Pasal 1"
					rules={[
						{
							required: true,
							message: 'Mohon Di Inputkan!',
						},
					]}>
					{authContext.clausesGroup.map((clauseGroup, clauseGroupIndex) => {
						return (
							<>
								<p>Pasal {clauseGroupIndex + 1}</p>
								<div className='stepOneCreateContract'>
									<Input
										placeholder='Title Pasal'
										style={{ marginRight: 20 }}
										value={clauseGroup.title}
										onChange={({ target: { value } }) => {
											authContext.changePasal(value, clauseGroupIndex)
										}}
									/>
									{/* <Button
                    icon={<FolderAddOutlined />}
                    style={{ marginRight: 10 }}
                    onClick={authContext.addNewClauseGroup}
                  >
                    Tambah Pasal
                  </Button> */}
									<Button
										icon={<FileAddOutlined />}
										onClick={() => setIsModalVisibleGroup(clauseGroupIndex)}
										style={{ marginRight: 10 }}>
										Pilih Template
									</Button>
									{clauseGroupIndex !== 0 ? (
										<Button
											icon={<DeleteOutlined />}
											onClick={() => authContext.removeClauseGroup(clauseGroupIndex)}></Button>
									) : null}
								</div>
								<div className='stepOneCreateContractClause'>
									{clauseGroup.clauses.map((clause, clauseIndex) => {
										return (
											<>
												<div className='stepOneCreateContractClause__item'>
													<ReactQuill
														onChange={value => authContext.changeClause(value, clauseGroupIndex, clauseIndex)}
														modules={modules}
														formats={formats}
														placeholder={'Ayat Pasal...'}
														className='clause-container'
														theme='snow'
														value={clause}
													/>
													<Button
														icon={<FolderAddOutlined />}
														style={{ marginRight: 10 }}
														onClick={() => authContext.addNewClause(clauseGroupIndex)}>
														Tambah Ayat
													</Button>
													<Button
														icon={<FileAddOutlined />}
														onClick={() => setIsModalVisibleItem(clauseIndex)}
														style={{ marginRight: 10 }}>
														Pilih Template
													</Button>

													{clauseIndex !== 0 ? (
														<Button
															icon={<DeleteOutlined />}
															onClick={() => authContext.removeClause(clauseGroupIndex, clauseIndex)}></Button>
													) : null}
												</div>
												<Divider />
												{/* ==================== Modal AYat================= */}
												<Modal
													title={`Template Ayat - ${clauseGroup.title}`}
													visible={isModalVisibleClauseItem === clauseIndex}
													onCancel={() => setIsModalVisibleItem(null)}
													footer={null}>
													<Search
														placeholder='Cari Berdasarkan Pasal atau Ayat'
														// onSearch={onSearch}
														onChange={onSearch}
														style={{ width: '100%', marginBottom: 14 }}
													/>

													<div className='pasal'>
														{pasal.isLoading ? (
															<h5>Loading...</h5>
														) : (
															pasal.items.map(item => {
																return (
																	<div>
																		<h5>{item.title}</h5>
																		<ul>
																			{item.templateClauses.map(ayat => {
																				return (
																					<li
																						onClick={() => {
																							authContext.changeClause(
																								ayat.content,
																								clauseGroupIndex,
																								isModalVisibleClauseItem
																							)
																							setIsModalVisibleItem(null)
																						}}>
																						<span
																							dangerouslySetInnerHTML={{
																								__html: `${ayat.content}`,
																							}}></span>
																					</li>
																				)
																			})}
																		</ul>
																	</div>
																)
															})
														)}
													</div>
												</Modal>
											</>
										)
									})}
								</div>
								<Divider />
								{/* ====================== PASAL ======================= */}
								<Modal
									title={'Template Pasal'}
									visible={isModalVisibleClauseGroup === clauseGroupIndex}
									onCancel={() => setIsModalVisibleGroup(null)}
									footer={null}>
									<Search
										placeholder='Cari Berdasarkan Pasal atau Ayat'
										// onSearch={onSearch}
										onChange={onSearch}
										style={{ width: '100%', marginBottom: 14 }}
									/>

									<div className='pasal'>
										{pasal.isLoading ? (
											<h5>Loading...</h5>
										) : (
											pasal.items.map((item, index) => {
												if (item.templateClauses.length === 0) {
													return (
														<div>
															<h5
																onClick={() => {
																	authContext.changePasal(item.title, isModalVisibleClauseGroup)
																	setTimeout(() => {
																		authContext.changePasalExtendTemplate(
																			{ title: item.title, clauses: [''] },
																			isModalVisibleClauseGroup
																		)
																	}, 1000)
																	setIsModalVisibleGroup(null)
																}}>
																{item.title}
															</h5>
														</div>
													)
												} else {
													let clauseItems = { title: item.title, clauses: [] }
													return (
														<div>
															<h5
																onClick={() => {
																	authContext.changePasal(item.title, isModalVisibleClauseGroup)
																	setTimeout(() => {
																		authContext.changePasalExtendTemplate(clauseItems, isModalVisibleClauseGroup)
																	}, 1000)

																	setIsModalVisibleGroup(null)
																}}>
																{item.title}
															</h5>
															<ul>
																{item.templateClauses.map(ayat => {
																	clauseItems = {
																		...clauseItems,
																		clauses: [...clauseItems.clauses, ayat.content],
																	}
																	return (
																		<li>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: `${ayat.content}`,
																				}}></span>
																		</li>
																	)
																})}
															</ul>
														</div>
													)
												}
											})
										)}
									</div>
								</Modal>

								{/* ====================== GROUP OF PASAL ======================= */}

								<Modal
									title={'Template Perjanjian'}
									visible={modalSetClauseVisible}
									onCancel={() => setModalSetClauseVisible(false)}
									footer={null}>
									<Search
										placeholder='Cari Berdasarkan Nama Template'
										// onSearch={onSearch}
										// onChange={onSearch}
										style={{ width: '100%', marginBottom: 14 }}
									/>

									{template.isLoading ? null : (
										<List
											size='small'
											bordered
											dataSource={template.items}
											renderItem={item => (
												<List.Item
													className='template_item'
													onClick={() => {
														if (item.templateClause.length === 0) {
															authContext.setClausesGroup([
																{
																	title: '',
																	clauses: [''],
																},
															])
														} else {
															const clauseMap = item.templateClause.map(clauseRes => ({
																title: clauseRes.title,
																clauses:
																	clauseRes.templateClauses.length === 0
																		? ['']
																		: clauseRes.templateClauses.map(cl => cl.content),
															}))

															authContext.setClausesGroup(clauseMap)
														}
														setModalSetClauseVisible(false)
													}}>
													{item.name}
												</List.Item>
											)}
										/>
									)}

									{/* <p>
										Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio nulla enim soluta explicabo ex
										ipsam, fuga magnam ea sit repellat voluptatibus amet quaerat ratione veritatis perspiciatis. Nam
										dignissimos reiciendis iste?
									</p> */}
								</Modal>
							</>
						)
					})}

					{/* Empty State */}
					<EmptyState />
				</Form.Item>
			</Form>
		</>
	)
}

export default StepTwo
