import React from 'react'
import { useState, useRef, useContext } from "react";
import SignatureInput from "../../../../Components/SignatureInput";

import { message, Button, Col, Row, Divider } from "antd";
import { ContractEditorContext } from '../../../../Context/ContractEditorContext'

import API from "../../../../Services/API";
import { useHistory } from "react-router-dom";

export default ({ }) => {

    const contractContext = useContext(ContractEditorContext)
    const history = useHistory()
    const signerID = 23;

    console.log('debug:contractContext.contract', contractContext.contract)

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
                {
                    contractContext.contract.data.isLoading ? null :
                        <div>
                            {
                                contractContext.contract.data.contractSigners.length < 1 ? null :
                                    <SignatureInput
                                        title={`Tanda Tangan ${contractContext.contract.data.contractSigners[0].companySigner.company.name}`}
                                        signer={contractContext.contract.data.contractSigners[0]}
                                        onSuccess={() => contractContext.fetchContract()}
                                    />
                            }
                        </div>
                }
            </Col>
            <Col xs={24} lg={12}>
                {
                    contractContext.contract.data.isLoading ? null :
                        <div>
                            {
                                contractContext.contract.data.contractSigners.length < 2 ? null :
                                    <SignatureInput
                                        title={`Tanda Tangan ${contractContext.contract.data.contractSigners[1].companySigner.company.name}`}
                                        signer={contractContext.contract.data.contractSigners[1]}
                                        onSuccess={() => contractContext.fetchContract()}
                                    />
                            }
                        </div>
                }
            </Col>
        </Row>

    );
};