import { useContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Login from './Pages/Login'
import Home from './Pages/Home'
import Contract from './Pages/Contract'
import CreateContract from './Pages/Contract/Create'
import UpdateContract from './Pages/Contract/Update'
import ContractEditor from './Pages/Contract/ContractEditor'
import Assign from './Pages/Assign'
import AssignWithToken from './Pages/Assign/AssignWithToken'
import Authentication from './Services/Authentication'
import React from 'react'


import { AuthContext } from './Context/AuthContext'

const Routes = () => {
	const authContext = useContext(AuthContext)

	return (
		<Switch>
			<Route exact path='/contract/create' component={CreateContract} />
			<Route exact path='/contract/:id/update' component={UpdateContract} />
			<Route exact path='/contract/:id' component={ContractEditor} />
			<Route exact path='/contract' component={Contract} />
			<Route path='/assign/:id' component={Assign} />
			<Route path='/assign' component={AssignWithToken} />
			<Route path="/login" component={Login} />
			<Route exact path='/'>
				{authContext.userAccess.token !== "" ? <Home /> : <Redirect to='/login' />}
			</Route>
		</Switch>
	)
}

export default Routes
