import React from 'react'
import { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";

import Layout from "../../Layout";
import { message, Button, Col, Row, Divider } from "antd";
import moment from "moment";
import { BeatLoader } from "react-spinners";

import API from "../../Services/API";

const Assign = ({ history, match }) => {
  const signerID = match.params.id;
  const [imageURL, setImageURL] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);

  const sigCanvas = useRef({});

  const clear = () => {
    setImageURL(null);
    setImageFile(null);
    sigCanvas.current.clear();
  };

  const save = () => {
    sigCanvas.current.getTrimmedCanvas().height = 100;
    sigCanvas.current.getTrimmedCanvas().width = 100;

    const imageElement = document.createElement("img");
    imageElement.src = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    imageElement.onload = function (event) {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH_HEIGHT = 100;
      canvas.width = MAX_WIDTH_HEIGHT;
      canvas.height = MAX_WIDTH_HEIGHT;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);

      const srcEncoded = ctx.canvas.toDataURL(event.target, "image/png");

      // Convert DATA URL to BLOB File
      ctx.canvas.toBlob((blob) => {
        let file = new File(
          [blob],
          `${moment().format("YYYY/MM/DD")}/signer=${signerID}.png`,
          { type: "image/png" }
        );
        setImageFile(file);
      }, "image/png");
      // End Convert
      setImageURL(srcEncoded);
    };
  };

  const assignSignature = () => {
    setImageUploading(true);

    API.assignSigningContract(signerID, { signingImage: imageFile }).then(
      (response) => {
        if (response.code === 200) {
          message.success("Berhasil Assign Tanda Tangan !");
          history.goBack();
        } else {
          message.error(`${response.errors.signingImage[0]}`);
        }
        setImageUploading(false);
      }
    );
  };

  const createImageCanvas = (file) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const imageElement = document.createElement("img");
      imageElement.src = event.target.result;

      // load in image id  = output
      imageElement.onload = function (e) {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH_HEIGHT = 100;
        canvas.width = MAX_WIDTH_HEIGHT;
        canvas.height = MAX_WIDTH_HEIGHT;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

        const srcEncoded = ctx.canvas.toDataURL(e.target, "image/png");

        // Convert DATA URL to BLOB File
        ctx.canvas.toBlob((blob) => {
          let file = new File(
            [blob],
            `${moment().format("YYYY/MM/DD")}/signer=${signerID}.png`,
            { type: "image/png" }
          );
          setImageFile(file);
        }, "image/png");
        // End Convert

        document.querySelector("#output").src = srcEncoded;
      };
    };
  };

  const processImage = () => {
    const file = document.querySelector("#signatureFile").files[0];

    if (!file) return;
    createImageCanvas(file);
  };

  return (
    <Layout>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h3>Assign Tanda Tangan :</h3>
        <p style={{ marginTop: 20 }}></p>
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="assignContract"
        >
          <div style={{ width: "50%" }} className="assignContract__input">
            <div
              id="dropContainer"
              style={{
                width: "100%",
                height: 60,
                border: "1px solid #eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                let fileInput = document.getElementById(`signatureFile`);

                // pretty simple -- but not for IE :(
                fileInput.files = event.dataTransfer.files;

                const dT = new DataTransfer();
                dT.items.add(event.dataTransfer.files[0]);
                fileInput.files = dT.files;

                setImageFile(event.dataTransfer.files[0]);
                createImageCanvas(event.dataTransfer.files[0]);

                event.preventDefault();
              }}
            >
              Drop File Here
            </div>
            <input
              type="file"
              id="signatureFile"
              onChange={({ target: { files } }) => {
                setImageFile(files[0]);
                processImage();
              }}
            />
          </div>

          <div className="assignContract__result">
            <img id="output" alt="File Upload" />
          </div>
        </div>
        <Divider>Atau Tanda Tangan :</Divider>
        <Row style={{ marginTop: 20 }}>
          <Col s={24} md={8} lg={8}>
            <SignaturePad
              onEnd={() => save()}
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
                style: {
                  border: "1px dashed grey",
                  width: "100%",
                  minHeight: 200,
                },
              }}
            />
          </Col>
          <Col s={24} md={8} lg={8}>
            <div
              style={{ display: "flex", flexDirection: "column", padding: 40 }}
            >
              <Button style={{ marginTop: 20 }} type="default" onClick={clear}>
                Ulangi
              </Button>
            </div>
          </Col>
          <Col s={24} md={8} lg={8}>
            <div
              style={{
                width: "100%",
                background: "#fafafa",
                padding: 20,
                height: imageURL ? "auto" : 200,
              }}
            >
              {imageURL ? (
                <img
                  src={imageURL}
                  width={100}
                  height={100}
                  alt="my signature"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    border: "1px dashed grey",
                    backgroundColor: "#fff",
                    width: 100,
                    height: 100,
                  }}
                />
              ) : (
                <p style={{ textAlign: "center", color: "grey" }}>
                  Result Signature Here
                </p>
              )}
            </div>
          </Col>
        </Row>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{ marginTop: 10, marginRight: 20 }}
            type="default"
            onClick={() => history.goBack()}
          >
            Kembali
          </Button>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => assignSignature()}
          >
            {imageUploading ? <BeatLoader size={7} color="#fff" /> : "Submit"}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Assign;
