import React from 'react'
import { useEffect, useState, Fragment } from 'react'

import Layout from '../../Layout'
import { Row, Col, Divider, Typography, message, notification } from 'antd'

import PieChart from '../../Components/PieChart'

import API from '../../Services/API'
import BarChart from '../../Components/BarChart'
import { FileTextOutlined } from '@ant-design/icons'

const { Title } = Typography

const Home = () => {
	const [statistik, setStatistik] = useState({
		isLoading: true,
		data: null,
	})
	const openNotificationWithIcon = () => {
		notification['info']({
			message: 'Kamu Di Assign Di Kesepakatan A',
			description: 'Silahkan Konfirmasi dan Assign TTD di Kesepakatan Tersebut',
		})
	}

	const [dataContract, setDataContract] = useState({
		typeTwo: 0,
		typeOne: 0,
	})

	const getStatistik = () => {
		API.statistikContract().then(response => {
			if (response.code === 200) {
				setStatistik({
					isLoading: false,
					data: response.data,
				})
			}
		})
	}

	const getListContract = () => {
		API.contract.list({ type: 1 }).then(response => {
			if (response.code === 200) {
				setDataContract(prevState => ({
					...prevState,
					typeOne: response.data.pagination.itemCount,
				}))
			}
		})

		API.contract.list({ type: 2 }).then(response => {
			if (response.code === 200) {
				setDataContract(prevState => ({
					...prevState,
					typeTwo: response.data.pagination.itemCount,
				}))
			}
		})
	}

	const userEmail = localStorage.getItem('email') || ''

	useEffect(() => {
		getListContract()
		getStatistik()
		message.success(`Hi ${userEmail} Selamat Datang Kembali !`)
		// openNotificationWithIcon();
	}, [])

	return (
		<div className='home'>
			<Layout>
				<div className='home-section'>
					<Row gutter={[16, 16]}>
						<Col xs={24} lg={12}>
							<div className='barchart_container'>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<Title level={4} style={{ marginBottom: 16 }}>
										Total Data Klasifikasi
									</Title>
									<Title level={4} style={{ marginBottom: 16 }}>
										{statistik.data ? statistik.data.total : ''}
									</Title>
								</div>

								<br />

								{statistik.isLoading ? null : (
									<Fragment>
										<BarChart
											title=''
											data={[
												['', 'Total'],
												['PTVP', statistik.data.classification.PTVP],
												['SMK', statistik.data.classification.SMK],
												['SUSLAT', statistik.data.classification.SUSLAT],
												['TU', statistik.data.classification.TU],
											]}
										/>

										<div className='barchart_footer'>
											{Object.keys(statistik.data.classification).map(classification => (
												<div key={classification}>
													<p>Total {classification}</p>
													<h4>{statistik.data.classification[classification]}</h4>
												</div>
											))}
										</div>
									</Fragment>
								)}
							</div>
						</Col>
						<Col xs={24} lg={12}>
							<div className='documentType_header'>
								<Title level={4} style={{ marginBottom: 16 }}>
									Statistik Kesepakatan
								</Title>
								<span>Berdasarkan Jenis Dokumen</span>
							</div>

							{!statistik.isLoading && (
								<Row gutter={[16, 16]}>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #7559ce 0%, #678fe9 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>{statistik.data.type.mou}</h1>
											<p style={{ margin: 0 }}>Total MoU</p>
										</div>
									</Col>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #a87efb 0%, #fe6eac 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>{statistik.data.type.moa}</h1>
											<p style={{ margin: 0 }}>Total MoA</p>
										</div>
									</Col>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #fe8c7f 0%, #fbd324 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>{statistik.data.type.mouAndMoa}</h1>
											<p style={{ margin: 0 }}>Total MoU & MoA</p>
										</div>
									</Col>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #11d7e4 0%, #48eaa1 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>{statistik.data.type.pks}</h1>
											<p style={{ margin: 0 }}>Total PKS</p>
										</div>
									</Col>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #7e9bfe 0%, #2eddfe 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>{statistik.data.type.ia}</h1>
											<p style={{ margin: 0 }}>Total IA</p>
										</div>
									</Col>
									<Col xs={24} md={8}>
										<div
											style={{
												background: `linear-gradient(to right, #7559ce 0%, #678fe9 100%)`,
											}}
											className='site-layout-background card'>
											<FileTextOutlined style={{ fontSize: '32px' }} />
											<h1 style={{ color: '#fff', fontSize: '40px', margin: 0 }}>
												{statistik.data.type.confirmationLetter}
											</h1>
											<p style={{ margin: 0 }}>Total Surat Pernyataan</p>
										</div>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
				</div>
				<Divider />
				<div className='home-section'>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12} lg={12}>
							<div
								style={{
									background: `linear-gradient(to right, #11d7e4 0%, #48eaa1 100%)`,
									height: '100%',
								}}
								className='site-layout-background card pie-chart'>
								<h3>Kerja Sama Berdasarkan Negara</h3>
								{statistik.isLoading ? null : (
									<Fragment>
										<PieChart
											title={``}
											data={[
												['Negara', 'Jumlah'],
												...Object.keys(statistik.data.country).map(country => [
													country === 'Undefined' ? 'Belum terdata' : country,
													statistik.data.country[country],
												]),
											]}
										/>
										<br />
										<h3>
											Total Negara :{' '}
											{Object.keys(statistik.data.country).filter(country => country !== 'Undefined').length}
										</h3>
									</Fragment>
								)}
							</div>
						</Col>

						<Col xs={24} md={12} lg={12}>
							<div
								style={{
									background: `linear-gradient(to right, #7559ce 0%, #678fe9 100%)`,
								}}
								className='site-layout-background card pie-chart'>
								<h3>Kerja Sama Berdasarkan Status</h3>
								{statistik.isLoading ? null : (
									<Fragment>
										<PieChart
											title={``}
											data={[
												['Status', 'Jumlah'],
												['Aktif', statistik.data.status.active],
												['Kadaluarsa', statistik.data.status.expired],
												['Akan Datang', statistik.data.status.upComing],
											]}
										/>
										<br />
										<h3>Total Keseluruhan : {statistik.data.status.total}</h3>
									</Fragment>
								)}
							</div>
						</Col>
					</Row>
				</div>

				<br />
				<br />
			</Layout>
		</div>
	)
}

export default Home
