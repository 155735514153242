import React from 'react'
import { Layout, Menu } from "antd";
import {
  ContainerOutlined,
  LogoutOutlined,
  HomeOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  IdcardOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../Assets/logo.png";

import Authentication from "../Services/Authentication";

const { Sider } = Layout;

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];
  const pathNameSecound = location.pathname.split("/")[2];
  const pathNameLast = location.pathname.split("/")[3];

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
      }}
      onCollapse={(collapsed, type) => {
      }}
    >
      <div className="logo" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <img src={Logo} alt="Logo" />

        <h1 style={{ color: 'white', marginTop: 10, textAlign: 'center' }}>Sistem Informasi Kesepakatan</h1>
      </div>
      <Menu theme="dark" mode="inline">
        <Menu.Item
          key="home"
          icon={<HomeOutlined />}
          style={{ background: pathName === "home" ? "#1890ff" : null }}
        >
          <Link to="/">Beranda</Link>
        </Menu.Item>
        <Menu.SubMenu key="sub1" icon={<FileDoneOutlined />} style={{
          background:
            (pathName === "contract" && pathNameSecound === undefined) ||
              pathName === "assign" ||
              (pathName === "contract" && pathNameLast === "update")
              ? "#1890ff"
              : null,
        }} title={<Link to="/contract" style={{ color: 'white' }}>Kesepakatan</Link>}>
          <Menu.ItemGroup key="g1" title="Kesepakatan">
            <Menu.Item
              key="contract"
              icon={<FileDoneOutlined />}
              style={{
                background:
                  (pathName === "contract" && pathNameSecound === undefined) ||
                    pathName === "assign" ||
                    (pathName === "contract" && pathNameLast === "update")
                    ? "#1890ff"
                    : null,
              }}
            >
              <Link to="/contract">Kelola Kesepakatan</Link>
            </Menu.Item>
            <Menu.Item
              key="createContract"
              icon={<FileAddOutlined />}
              style={{
                background: pathNameSecound === "create" ? "#1890ff" : null,
              }}
            >
              <Link to="/contract/create">Buat Kesepakatan Baru</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key="g1" title="Instansi">
            <Menu.Item
              key="Instance"
              icon={<IdcardOutlined />}
            >
              <Link to="/instance">Kelola Instansi</Link>
            </Menu.Item>
            <Menu.Item
              key="createInstance"
              icon={<UserAddOutlined />}
              style={{
                background: pathNameSecound === "create" ? "#1890ff" : null,
              }}
            >
              <Link to="/instance/create">Buat Instansi Baru</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          {
            true ? null :
              <Menu.ItemGroup key="g2" title="Template">
                <Menu.Item key="3" icon={<FileAddOutlined />}>Buat Template Baru</Menu.Item>
                <Menu.Item key="4" icon={<ContainerOutlined />}>Kelola Template</Menu.Item>
              </Menu.ItemGroup>
          }
        </Menu.SubMenu>

        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          <span href="#" onClick={() => Authentication.logout(history)}>Logout</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
