import BaseAPI from "./BaseAPI"
import queryString from 'query-string'

export default class Template {
    static list = (parameters = {}) => {
        const payload = BaseAPI.cleanPayload({
            expand: 'templateClause',
            keyword: parameters.keyword,
        })
        return BaseAPI.fetch(`contract/template?${queryString.stringify(payload)}`)
    }
}
