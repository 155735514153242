import React from 'react'
import ContractEditorProvider from '../../../Context/ContractEditorContext'
import Editor from './Editor'


const ContractEditor = ({ match }) => {
	const contractID = match.params.id
	return (
		<ContractEditorProvider initialID={contractID}>
			<Editor />
		</ContractEditorProvider>

	)
}

export default ContractEditor
