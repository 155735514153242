import queryString from 'query-string'

import BaseAPI from './API/BaseAPI'
import Template from './API/Template'
import Contract from './API/Contract'

export default class API {
	static template = Template
	static contract = Contract

	
	static assignSignerContract = (contractID, body = {}) => {
		const reqBody = BaseAPI.cleanPayload({
			companyName: body.companyName,
			companyAddress: body.companyAddress,
			companyAbout: body.companyAbout,
			companyLogo: body.companyLogo,
			officerName: body.officerName,
			officerPosition: body.officerPosition,
			contractNumber: body.contractNumber,
			companyType: body.companyType,
			countryID: body.countryID,
		})
		return BaseAPI.fetch(`contract/${contractID}/assign/signer`, {
			body: reqBody,
			method: 'POST',
		})
	}

	static assignSignerContractUpdate = (contractIDSigner, body = {}) => {
		const reqBody = BaseAPI.cleanPayload({
			companyName: body.companyName,
			companyAddress: body.companyAddress,
			companyAbout: body.companyAbout,
			companyLogo: body.companyLogo,
			officerName: body.officerName,
			officerPosition: body.officerPosition,
			contractNumber: body.contractNumber,
		})
		return BaseAPI.fetch(`contract/${contractIDSigner}/update/signer`, {
			body: reqBody,
			method: 'POST',
		})
	}

	static assignSigningContract = (contractSignerID, body = {}, token = null) => {
		const queryParam = BaseAPI.cleanPayload({
			token,
		})

		const reqBody = BaseAPI.cleanPayload({
			signingImage: body.signingImage,
		})

		if (!token) {
			return BaseAPI.fetch(`contract/${contractSignerID}/assign/signing`, {
				body: reqBody,
				method: 'POST',
			})
		}

		return BaseAPI.fetch(`contract/${contractSignerID}/assign/signing?${queryString.stringify(queryParam)}`, {
			body: reqBody,
			method: 'POST',
		})
	}

	static assignClauseContract = (id, body = {}) => {
		const reqBody = BaseAPI.cleanPayload(body)
		return BaseAPI.fetch(`contract/${id}/assign/clause`, {
			body: reqBody,
			method: 'POST',
		})
	}

	static statistikContract = () => {
		return BaseAPI.fetch(`contract/statistic`)
	}

	static verifySigner = token => {
		return BaseAPI.fetch(`contract/check-signer/${token}`)
	}

	static exportContract = contractID => {
		return BaseAPI.fetch(`contract/${contractID}/export`)
	}

	static getCooperationDirector = (parameters = {}) => {
		return BaseAPI.fetch(`contract/cooperation-director`)
	}
	static getCompany = (parameters = {}) => {
		const queryParam = BaseAPI.cleanPayload({
			expand: 'companySigners',
			keyword: parameters.keyword,
		})
		return BaseAPI.fetch(`contract/company?${queryString.stringify(queryParam)}`)
	}

	static listClauseGroup = (parameters = {}) => {
		const payload = BaseAPI.cleanPayload({
			expand: 'templateClauses',
			keyword: parameters.keyword,
		})
		return BaseAPI.fetch(`contract/template-clause-group?${queryString.stringify(payload)}`)
	}

	static setOfTemplate = (parameters = {}) => {
		const payload = BaseAPI.cleanPayload({
			expand: 'templateClause',
			keyword: parameters.keyword,
		})
		return BaseAPI.fetch(`contract/template?${queryString.stringify(payload)}`)
	}

	static getCountryData = (parameters = {}) => {
		const payload = BaseAPI.cleanPayload({
			keyword: parameters.keyword,
			pageSize: 200,
		})
		return BaseAPI.fetch(`contract/country?${queryString.stringify(payload)}`)
	}

	

	static listClauseGroup = (parameters = {}) => {
		const payload = BaseAPI.cleanPayload({
			expand: 'templateClauses',
			keyword: parameters.keyword,
		})
		return BaseAPI.fetch(`contract/template-clause-group?${queryString.stringify(payload)}`)
	}

	// Login
	static login = (username, password) => {
		return BaseAPI.fetch(`access/login`, {
			body: { username, password },
			method: 'POST',
		})
	}

}
