import React from 'react'
import { createContext, useState, useEffect } from 'react'
import moment from 'moment'

import API from '../Services/API'

export const ContractEditorContext = createContext()
export const ContractEditorContextConsumer = ContractEditorContext.Consumer

const ContractEditorProvider = ({ children, initialID }) => {
    const [contractID, setContractID] = useState(initialID)
    const [contract, setContract] = useState({ isLoading: true, data: null, isNewRecord: false })
    const [clauseGroups, setClauseGroups] = useState([])
    const [formContract, setFormContract] = useState({
        type: null,
        name: null,
        location: null,
        contractStart: moment().format('YYYY-MM-DD'),
        contractEnd: moment().format('YYYY-MM-DD'),
    })
    const [formFirstParty, setFormFirstParty] = useState({
        companyName: null,
        companyAddress: null,
        companyAbout: null,
        companyLogo: null,
        officerName: null,
        officerPosition: null,
        contractNumber: null,
    })
    const [formSecondParty, setFormSecondParty] = useState({
        companyName: null,
        companyAddress: null,
        companyAbout: null,
        companyLogo: null,
        officerName: null,
        officerPosition: null,
        contractNumber: null,
        countryID: null,
    })

    useEffect(() => {
        if (contractID !== initialID) setContractID(initialID)
        if (initialID == null) setContract({ ...contract, isNewRecord: true })
    }, [])

    useEffect(() => fetchContract(), [contractID])

    const fetchContract = () => {
        if (contractID) {
            setContract(previous => ({ ...previous, isLoading: true }))
            API.contract.detail(contractID).then(response => {
                if (response.code === 200) {
                    setContract({
                        isLoading: false,
                        data: response.data,
                    })

                    setFormContract({
                        type: response.data.type,
                        name: response.data.name,
                        location: response.data.location,
                        contractStart: response.data.contractStart,
                        contractEnd: response.data.contractEnd,
                    })
                    setClauseGroups(response.data.clauseGroups)
                    if (response.data.contractSigners.length > 0) {
                        setFormFirstParty({
                            companyName: response.data.contractSigners[0].companySigner.company.name,
                            companyAddress: response.data.contractSigners[0].companySigner.company.address,
                            companyAbout: response.data.contractSigners[0].companySigner.company.detail,
                            companyLogo: response.data.contractSigners[0].companySigner.company.urlLogo,
                            officerName: response.data.contractSigners[0].companySigner.officerName,
                            officerPosition: response.data.contractSigners[0].companySigner.officerPosition,
                            contractNumber: response.data.contractSigners[0].contractNumber,
                        })
                    }
                    if (response.data.contractSigners.length > 1) {
                        setFormSecondParty({
                            companyName: response.data.contractSigners[1].companySigner.company.name,
                            companyAddress: response.data.contractSigners[1].companySigner.company.address,
                            companyAbout: response.data.contractSigners[1].companySigner.company.detail,
                            companyLogo: response.data.contractSigners[1].companySigner.company.urlLogo,
                            officerName: response.data.contractSigners[1].companySigner.officerName,
                            officerPosition: response.data.contractSigners[1].companySigner.officerPosition,
                            contractNumber: response.data.contractSigners[1].contractNumber,
                            countryID: response.data.contractSigners[1].companySigner.company.countryID,
                        })
                    }
                }
            })
        } else {
            setContract({ data: null, isLoading: false, isNewRecord: true })
        }
    }

    const appendClauseGroup = () => setClauseGroups(prevState => [...prevState, {
        ID: null,
        title: null,
        clausal: [
            {
                ID: null,
                clauseGroupID: null,
                content: '',
            },
        ],
    }])
    const removeClauseGroupByIndex = index => setClauseGroups(clauseGroups.filter((data, dataIndex) => dataIndex !== index))

    const addNewClause = clauseIndexGroup => {
        let clauseGroupsItems = [...clauseGroups]
        let clause = {
            ...clauseGroupsItems[clauseIndexGroup],
            clausal: [
                ...clauseGroupsItems[clauseIndexGroup].clausal,
                {
                    ID: null,
                    clauseGroupID: null,
                    content: '',
                },
            ],
        }
        clauseGroupsItems[clauseIndexGroup] = clause
        setClauseGroups(clauseGroupsItems)
    }

    const setLoadingRemoveClause = async (clauseIndexGroup, deleteIndex) => {
        setClauseGroups(previous => {
            let updated = [...previous.map((clauseGroup, searchClauseGroupIndex) => {
                if (searchClauseGroupIndex !== clauseIndexGroup) {
                    return { ...clauseGroup }
                }
                return {
                    ...clauseGroup,
                    clausal: clauseGroup.clausal.map((data, searchClausalIndex) => {
                        if (searchClausalIndex !== deleteIndex) {
                            return data
                        }

                        return {
                            ...data,
                            isLoadingRemove: true,
                        }
                    })
                }
            })]

            return updated
        })
    }

    const removeClause = async (clauseIndexGroup, deleteIndex) => {
        setClauseGroups(previous => {
            let updated = [...previous.map((clauseGroup, searchClauseGroupIndex) => {
                if (searchClauseGroupIndex !== clauseIndexGroup) {
                    return clauseGroup
                }
                clauseGroup.clausal = clauseGroup.clausal.filter((data, searchClausalIndex) => searchClausalIndex !== deleteIndex)
                return clauseGroup
            })]

            return updated
        })
    }

    const changePasal = (value, clauseIndexGroup) => {
        let clauseGroupItems = [...clauseGroups]
        let clause = {
            ...clauseGroupItems[clauseIndexGroup],
            title: value,
        }
        clauseGroupItems[clauseIndexGroup] = clause

        setClauseGroups(clauseGroupItems)
    }

    const changeClause = (value, clauseIndexGroup, index) => {
        let clauseGroupItemsClauses = [...clauseGroups[clauseIndexGroup].clausal]
        clauseGroupItemsClauses[index].content = value

        let clauseGroupItems = [...clauseGroups]
        let clause = {
            ...clauseGroupItems[clauseIndexGroup],
            clausal: clauseGroupItemsClauses,
        }
        clauseGroupItems[clauseIndexGroup] = clause
        setClauseGroups(clauseGroupItems)
    }

    const changePasalExtendTemplate = (data, clauseIndexGroup) => {
        let clauseGroupItems = [...clauseGroups]
        let clause = data
        clauseGroupItems[clauseIndexGroup] = clause

        setClauseGroups(clauseGroupItems)
    }
    const removeGroup = clauseGroupID => {
        let idGroup = {}

        idGroup = {
            ...idGroup,
            ...{ [`removes[clauseGroup][0]`]: clauseGroupID },
        }

        API.assignClauseContract(contractID, {
            ...idGroup,
        }).then(response => {
            if (response.code === 200) {
            } else {
            }
        })
    }

    const removeGroupItem = clauseID => {
        let idGroup = {}

        idGroup = {
            ...idGroup,
            ...{ [`removes[clause][0]`]: clauseID },
        }

        API.assignClauseContract(contractID, {
            ...idGroup,
        }).then(response => {
            if (response.code === 200) {
            } else {
            }
        })
    }

    const submit = () => {
        let allClausesGroup = clauseGroups
        let titles = {}
        let titlesUpdate = {}
        let idsUpdate = {}
        // Clause
        let clauses = {}
        let clausesUpdate = {}
        let idsUpdateClauses = {}

        let globalClauseIndex = 0;

        for (let index = 0; index < clauseGroups.length; index++) {
            if (allClausesGroup[index].ID === null) {
                titles = {
                    ...titles,
                    ...{ [`create[${index}][title]`]: allClausesGroup[index].title },
                }
            } else {
                titlesUpdate = {
                    ...titlesUpdate,
                    ...{
                        [`updates[clauseGroup][${index}][title]`]: allClausesGroup[index].title,
                    },
                }

                idsUpdate = {
                    ...idsUpdate,
                    ...{
                        [`updates[clauseGroup][${index}][ID]`]: allClausesGroup[index].ID,
                    },
                }
            }

            for (let clauseIndex = 0; clauseIndex < allClausesGroup[index].clausal.length; clauseIndex++) {
                if (
                    allClausesGroup[index].clausal[clauseIndex].ID === '' ||
                    allClausesGroup[index].clausal[clauseIndex].ID === null
                ) {
                    clauses = {
                        ...clauses,
                        ...{
                            [`create[${index}][clause][${globalClauseIndex}]`]: allClausesGroup[index].clausal[clauseIndex].content,
                        },
                    }
                    if (allClausesGroup[index].ID !== null) {
                        clauses = {
                            ...clauses,
                            ...{
                                [`create[${index}][clauseGroupID]`]: allClausesGroup[index].ID,
                            },
                        }
                    }
                } else {
                    clausesUpdate = {
                        ...clausesUpdate,
                        ...{
                            [`updates[clause][${globalClauseIndex}][content]`]: allClausesGroup[index].clausal[clauseIndex].content,
                        },
                    }

                    idsUpdateClauses = {
                        ...idsUpdateClauses,
                        ...{
                            [`updates[clause][${globalClauseIndex}][ID]`]: allClausesGroup[index].clausal[clauseIndex].ID,
                        },
                    }
                }
                globalClauseIndex++;
            }
        }

        return API.assignClauseContract(contract.data.ID, {
            ...titles,
            ...titlesUpdate,
            ...idsUpdate,
            ...clauses,
            ...clausesUpdate,
            ...idsUpdateClauses,
        }).then(response => {
            return new Promise((resolve, reject) => {
                if (response.code === 200) {
                    fetchContract()
                    resolve(response.data)
                } else {
                    reject(response.message)
                }
            })
        })
    }



    return (
        <ContractEditorContext.Provider
            value={{
                contract, setContract,
                fetchContract,
                clauseGroups, setClauseGroups,
                formContract, setFormContract,
                formFirstParty, setFormFirstParty,
                formSecondParty, setFormSecondParty,

                submit,

                appendClauseGroup, removeClauseGroupByIndex,
                addNewClause, removeClause,
                changePasal, changeClause,
                changePasalExtendTemplate,
                removeGroupItem, removeGroup,
                setLoadingRemoveClause
            }}>
            {children}
        </ContractEditorContext.Provider>
    )
}

export default ContractEditorProvider
