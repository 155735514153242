import BaseAPI from "./BaseAPI"
import queryString from 'query-string'

/**
 * API: Contract
 */
export default class Contract {

    /**
     * List
     * @param {*} parameters 
     * @returns 
     */
    static list = (parameters = {}) => {
        const payload = BaseAPI.cleanPayload({
            type: parameters.type,
            sort: parameters.sort || 'ID',
            order: parameters.order || 'desc',
            keyword: parameters.keyword,
            expand: 'contractSigners,cooperationDirector',
            status: parameters.status,
            signing: parameters.signing,
            pageSize: parameters.pageSize || 500,
            deleted: parameters.deleted,
            classificationType: parameters.classificationType,
        })
        return BaseAPI.fetch(`contract?${queryString.stringify(payload)}`)
    }

    /**
     * Archive
     * @param {*} id 
     * @param {*} body 
     * @returns 
     */
    static archive = (id, body = {}) => {
        const reqBody = BaseAPI.cleanPayload({
            deleted: body.deleted,
        })

        return BaseAPI.fetch(`contract/${id}/archive`, {
            body: reqBody,
            method: 'POST',
        })
    }

    /**
     * Detail
     * @param {*} ID 
     * @returns 
     */
    static detail = ID => {
        const payload = BaseAPI.cleanPayload({
            expand: 'contractSigners,clauseGroups',
        })
        return BaseAPI.fetch(`contract/${ID}?${queryString.stringify(payload)}`)
    }

    /**
     * Create
     * @param {*} body 
     * @returns 
     */
    static create = (body = {}) => {
        const reqBody = BaseAPI.cleanPayload({
            type: body.type,
            name: body.name,
            location: body.location,
            classificationType: body.classificationType,
            cooperationDirector: body.cooperationDirector,
            contractStart: body.contractStart,
            contractEnd: body.contractEnd,
        })
        return BaseAPI.fetch(`contract`, {
            body: reqBody,
            method: 'POST',
        })
    }

    static update = (ID, body = {}) => {
        const queryParam = BaseAPI.cleanPayload({
            expand: 'contractSigners',
        })

        const reqBody = BaseAPI.cleanPayload({
            type: body.type,
            name: body.name,
            location: body.location,
            contractStart: body.contractStart,
            contractEnd: body.contractEnd,
        })
        return BaseAPI.fetch(`contract/${ID}?${queryString.stringify(queryParam)}`, {
            body: reqBody,
            method: 'POST',
        })
    }

    static assignNumber = (ID, number) => {
        return BaseAPI.fetch(`contract/${ID}/assign/number`, {
            body: { number },
            method: 'POST',
        })
    }

}
