import React from 'react'
import { useState, useRef, useContext } from "react";
import API from "../../../../Services/API";
import { message, Button, Col, Row, Input } from "antd";
import { ContractEditorContext } from '../../../../Context/ContractEditorContext'

export default ({ }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const contractContext = useContext(ContractEditorContext)

    const updateFormFirstParty = parameters => contractContext.setFormFirstParty(prevState => ({ ...prevState, ...parameters }))
    const updateFormSecondParty = parameters => contractContext.setFormSecondParty(prevState => ({ ...prevState, ...parameters }))

    const submit = async () => {
        setIsSubmitting(true)
        const contractSigners = contractContext.contract.data.contractSigners
        await saveContractSigner(contractSigners[0], contractContext.formFirstParty)
        await saveContractSigner(contractSigners[1], contractContext.formSecondParty)
        setIsSubmitting(false)
        message.success('Kesepakatan Berhasil Di Update!')
    }

    const saveContractSigner = (contractSigner, form) => {
        return API.assignSignerContractUpdate(contractSigner.ID, form).then(response => {
            return new Promise((resolve, reject) => {
                if (response.code === 200) {
                    resolve(response.data)
                } else {
                    reject(response.message)
                }
            })
        })
    }

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <h1>
                    <small>Nomor Kesepakatan</small><br />
                    {contractContext.formFirstParty.companyName}
                </h1>
                <div style={{marginLeft: 45, marginRight: 45}}>
                    <Input
                        placeholder="Nomor Kesepakatan" size={"large"} bordered={false}
                        style={{ fontSize: '4em', borderBottomWidth: 1, borderBottomColor: '#d9d9d9', borderBottomStyle: 'solid', textAlign: 'center' }}
                        value={contractContext.formFirstParty.contractNumber}
                        onChange={({ target: { value } }) => {
                            updateFormFirstParty({
                                contractNumber: value,
                            })
                        }}
                    />
                </div>
            </Col>
            <Col xs={24} md={12}>
                <h1>
                    <small>Nomor Kesepakatan</small><br />
                    {contractContext.formSecondParty.companyName}
                </h1>
                <div style={{marginLeft: 45, marginRight: 45}}>
                    <Input
                        placeholder="Nomor Kesepakatan" size={"large"} bordered={false}
                        style={{ fontSize: '4em', borderBottomWidth: 1, borderBottomColor: '#d9d9d9', borderBottomStyle: 'solid', textAlign: 'center' }}
                        value={contractContext.formSecondParty.contractNumber}
                        onChange={({ target: { value } }) => {
                            updateFormSecondParty({
                                contractNumber: value,
                            })
                        }}
                    />
                </div>
            </Col>
            <Col xs={24} lg={24}>
                <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}>
                    <Button onClick={submit} loading={isSubmitting} type="primary">Simpan</Button>
                </div>
            </Col>

        </Row>

    );
};