import React from 'react'
import { useState } from "react";
import { Layout, Avatar, Typography, Space, Popover } from "antd";
import { BellOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Header } = Layout;

const HeaderComponent = () => {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  return (
    <Header
      className="site-layout-sub-header-background"
      style={{
        padding: "0 36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Popover
        content={<span onClick={hide}>Konfirmasi</span>}
        title="Kamu Di Assign Pihak A !"
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        placement="bottom"
      >
        <BellOutlined style={{ marginRight: 20, cursor: "pointer" }} />
      </Popover>
      <Space>
        <Text
          style={{
            color: "#6c757d",
          }}
        >
          {localStorage.getItem("email") || "Guest@mitrasdudi.id"}
        </Text>
        <Avatar
          src={localStorage.getItem('avatarURL') || null}
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        >
          {localStorage.getItem("email") ? localStorage.getItem("email")[0] : "G"}
        </Avatar>
      </Space>
    </Header>
  );
};

export default HeaderComponent;
