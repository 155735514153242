import React from 'react'
import { useState, useEffect, useContext } from 'react'
import API from '../../../../Services/API'
import EmptyState from './EmptyState'

import { Input, Button, Row, Col, Form, Divider, Modal, Popconfirm, Progress, message, Card, Skeleton, Tree } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FileAddOutlined, DeleteOutlined, FolderAddOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { ContractEditorContext } from '../../../../Context/ContractEditorContext'

const Quill = ReactQuill.Quill
let Font = Quill.import('formats/font')
Font.whitelist = ['TimesNewRoman', 'Arial']
Quill.register(Font, true)

const { Search } = Input

export default () => {

    const contractContext = useContext(ContractEditorContext)
    const [previousClauseGroups, setPreviousClauseGroups] = useState([])

    const [isModalVisibleClauseGroup, setIsModalVisibleGroup] = useState(null)
    const [isModalVisibleClauseItem, setIsModalVisibleItem] = useState(null)
    const [isModalVisibleTemplate, setIsModalVisibleTemplate] = useState(false)

    const [templates, setTemplates] = useState({ isLoading: true, items: [] })
    const [keyword, setKeyword] = useState(null)
    const [progress, setProgress] = useState(0)
    const [templateClauseGroups, setTemplateClauseGroups] = useState({ isLoading: true, items: [] })

    const onSearch = value => setKeyword(value.target.value)
    const fetchTemplateClauseGroups = () => {
        API.listClauseGroup({ keyword: keyword }).then(response => {
            if (response.code === 200) {
                setTemplateClauseGroups({
                    isLoading: false,
                    items: response.data.list,
                })
            }
        })
    }
    const fetchTemplate = () => {
        API.template.list().then(response => {
            if (response.code === 200) {
                setTemplates({
                    isLoading: false,
                    items: response.data.list,
                })
            }
        })
    }

    const useTemplate = templateID => {
        const template = templates.items.find((search) => search.ID == templateID)
        if (template) {
            contractContext.setClauseGroups([])
            setIsModalVisibleTemplate(false)
            setTimeout(() => {
                let replacedClauseGroups = []
                template.templateClause.map(templateClauseGroup => {
                    replacedClauseGroups.push({
                        ID: null,
                        clausal: [...templateClauseGroup.templateClauses.map(templateClause => ({
                            content: templateClause.content,
                            ID: null,
                            clauseGroupID: null,
                        }))],
                        contractID: contractContext.contract.data.ID,
                        title: templateClauseGroup.title
                    })
                })
                setPreviousClauseGroups(contractContext.clauseGroups)
                contractContext.setClauseGroups(replacedClauseGroups)
            }, 600)
        } else {
            message.error('Template tidak dapat digunakan/ditemukan')
        }
    }

    useEffect(() => fetchTemplateClauseGroups(), [keyword])
    useEffect(() => {
        fetchTemplateClauseGroups()
        fetchTemplate()
    }, [])

    const [isSubmitting, setIsSubmitting] = useState(false)

    const submit = async () => {
        setIsSubmitting(true)
        setProgress(0)
        if (previousClauseGroups.length) {
            const step = 100 / (previousClauseGroups.length + 1)
            previousClauseGroups.map(async previousClauseGroup => {
                await API.assignClauseContract(contractContext.contract.data.ID, {
                    [`removes[clauseGroup][0]`]: previousClauseGroup.ID,
                })
                setProgress(previous => {
                    return previous + step
                })

            })
        }
        contractContext.submit()
            .then(_ => {
                message.info('Kesepakatan telah berhasil diperbaharui')
                setTimeout(_ => setProgress(100), 1000)
            })
            .catch(text => message.error(text))
            .finally(_ => setIsSubmitting(false))
    }

    const clauseGroups = contractContext.clauseGroups

    return (
        <div>
            <div className='contract' style={{ marginBottom: 24, flex: 1, flexDirectionL: 'row', display: 'flex' }}>
                <h3 style={{ flex: 1 }}>Pengaturan Pasal-Pasal</h3>
                <Button
                    icon={<FileAddOutlined />}
                    onClick={() => setIsModalVisibleTemplate(true)}
                    style={{ marginRight: 10 }}>
                    Gunakan Template
                </Button>
            </div>
            <div style={{ background: '#fff', padding: 20 }}>
                <Row>
                    <Col xs={24} md={24} lg={24}>
                        <div
                            style={{
                                background: '#fafafa',
                                padding: 10,
                                width: '100%',
                                borderRadius: 4,
                            }}>
                            <Form layout='vertical' size='small'>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Mohon Di Inputkan!',
                                        },
                                    ]}>
                                    {clauseGroups.map((clauseGroup, clauseGroupIndex) => {
                                        return (
                                            <div key={clauseGroupIndex}>
                                                <p>Pasal {clauseGroupIndex + 1}</p>

                                                <div className='stepOneCreateContract'>
                                                    <Input
                                                        placeholder='Title Pasal'
                                                        style={{ marginRight: 20 }}
                                                        value={clauseGroup.title}
                                                        onChange={({ target: { value } }) => {
                                                            contractContext.changePasal(value, clauseGroupIndex)
                                                        }}
                                                    />
                                                    <Button
                                                        icon={<FileAddOutlined />}
                                                        onClick={() => {
                                                            setIsModalVisibleGroup(clauseGroupIndex)
                                                        }}
                                                        style={{ marginRight: 10 }}>
                                                        Pilih Template
                                                    </Button>
                                                    <Button
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            contractContext.removeClauseGroupByIndex(clauseGroupIndex)
                                                            contractContext.removeGroup(clauseGroup.ID)
                                                        }}></Button>
                                                </div>
                                                <div className='stepOneCreateContractClause'>
                                                    {clauseGroup.clausal.map((clause, clauseIndex) => {
                                                        return (
                                                            <div key={clauseIndex} style={{ opacity: clause.isLoadingRemove ? 0.2 : 1.0 }}>
                                                                <div className='stepOneCreateContractClause__item'>
                                                                    <div style={{ flex: 1, flexDirection: 'unset', display: 'flex' }}>
                                                                        <b style={{ marginRight: 10 }}>({clauseIndex + 1}). </b>
                                                                        <ReactQuill
                                                                            onChange={value => contractContext.changeClause(value, clauseGroupIndex, clauseIndex)}
                                                                            modules={{
                                                                                toolbar: [
                                                                                    [{ font: Font.whitelist }],
                                                                                    ['bold', 'italic', 'underline'],
                                                                                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                                                                                ],
                                                                            }}
                                                                            formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent']}
                                                                            placeholder={'Ayat Pasal...'}
                                                                            className='clause-container'
                                                                            style={{ flex: 1 }}
                                                                            theme='snow'
                                                                            value={clause.content}
                                                                        />
                                                                    </div>
                                                                    <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'row', width: '285px' }}>
                                                                        {clauseIndex === (clauseGroup.clausal.length - 1) ? (
                                                                            <Button
                                                                                icon={<FolderAddOutlined />}
                                                                                style={{ marginRight: 10 }}
                                                                                onClick={() => contractContext.addNewClause(clauseGroupIndex)}>
                                                                                Tambah Ayat
                                                                            </Button>
                                                                        ) : null}
                                                                        <Button
                                                                            icon={<FileAddOutlined />}
                                                                            onClick={() => {
                                                                                setIsModalVisibleItem(clauseIndex)
                                                                            }}
                                                                            style={{ marginRight: 10 }}>
                                                                            Pilih Template
                                                                        </Button>

                                                                        {clauseIndex !== 0 ? (
                                                                            <Popconfirm
                                                                                title="Apakah anda yakin akan menghapus ayat ini?"
                                                                                onConfirm={() => {
                                                                                    contractContext.setLoadingRemoveClause(clauseGroupIndex, clauseIndex)
                                                                                    setTimeout(() => {
                                                                                        contractContext.removeClause(clauseGroupIndex, clauseIndex)
                                                                                        contractContext.removeGroupItem(clause.ID)
                                                                                    }, 1000)
                                                                                }}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <Button
                                                                                    icon={<DeleteOutlined />}
                                                                                ></Button>
                                                                            </Popconfirm>

                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <Divider />
                                                                {/* ==================== Modal AYat================= */}
                                                                <Modal
                                                                    title={`Template Ayat - ${clauseGroup.title}`}
                                                                    visible={isModalVisibleClauseItem === clauseIndex}
                                                                    onCancel={() => setIsModalVisibleItem(null)}
                                                                    footer={null}>
                                                                    <Search
                                                                        placeholder='Cari Berdasarkan Pasal atau Ayat'
                                                                        // onSearch={onSearch}
                                                                        onChange={onSearch}
                                                                        style={{
                                                                            width: '100%',
                                                                            marginBottom: 14,
                                                                        }}
                                                                    />

                                                                    <div className='templateClauseGroups'>
                                                                        {templateClauseGroups.isLoading ? (
                                                                            <h5>Loading...</h5>
                                                                        ) : (
                                                                            templateClauseGroups.items.map(item => {
                                                                                return (
                                                                                    <div>
                                                                                        <h5>{item.title}</h5>
                                                                                        <ul>
                                                                                            {item.templateClauses.map(ayat => {
                                                                                                return (
                                                                                                    <li
                                                                                                        onClick={() => {
                                                                                                            contractContext.changeClause(
                                                                                                                ayat.content,
                                                                                                                clauseGroupIndex,
                                                                                                                isModalVisibleClauseItem
                                                                                                            )
                                                                                                            setIsModalVisibleItem(null)
                                                                                                        }}>
                                                                                                        <span
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: `${ayat.content}`,
                                                                                                            }}></span>
                                                                                                    </li>
                                                                                                )
                                                                                            })}
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        )}
                                                                    </div>
                                                                </Modal>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <Divider />
                                                {/* ====================== PASAL ======================= */}
                                                <Modal
                                                    title={'Template Pasal'}
                                                    visible={isModalVisibleClauseGroup === clauseGroupIndex}
                                                    onCancel={() => setIsModalVisibleGroup(null)}
                                                    footer={null}>
                                                    <Search
                                                        placeholder='Cari Berdasarkan Pasal atau Ayat'
                                                        // onSearch={onSearch}
                                                        onChange={onSearch}
                                                        style={{ width: '100%', marginBottom: 14 }}
                                                    />

                                                    <div className='templateClauseGroups'>
                                                        {templateClauseGroups.isLoading ? (
                                                            <h5>Loading...</h5>
                                                        ) : (
                                                            templateClauseGroups.items.map((item, index) => {
                                                                if (item.templateClauses.length === 0) {
                                                                    return (
                                                                        <div>
                                                                            <h5
                                                                                onClick={() => {
                                                                                    contractContext.changePasal(item.title, isModalVisibleClauseGroup)
                                                                                    setTimeout(() => {
                                                                                        contractContext.changePasalExtendTemplate(
                                                                                            {
                                                                                                ID: item.ID,
                                                                                                title: item.title,
                                                                                                clausal: [
                                                                                                    {
                                                                                                        ID: null,
                                                                                                        clauseGroupID: null,
                                                                                                        content: '',
                                                                                                    },
                                                                                                ],
                                                                                            },
                                                                                            isModalVisibleClauseGroup
                                                                                        )
                                                                                    }, 1000)
                                                                                    setIsModalVisibleGroup(null)
                                                                                }}>
                                                                                {item.title}
                                                                            </h5>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    let clauseItems = {
                                                                        ID: item.ID,
                                                                        title: item.title,
                                                                        clausal: [
                                                                            {
                                                                                ID: null,
                                                                                clauseGroupID: null,
                                                                                content: '',
                                                                            },
                                                                        ],
                                                                    }
                                                                    return (
                                                                        <div>
                                                                            <h5
                                                                                onClick={() => {
                                                                                    contractContext.changePasal(item.title, isModalVisibleClauseGroup)
                                                                                    setTimeout(() => {
                                                                                        contractContext.changePasalExtendTemplate(clauseItems, isModalVisibleClauseGroup)
                                                                                    }, 1000)

                                                                                    setIsModalVisibleGroup(null)
                                                                                }}>
                                                                                {item.title}
                                                                            </h5>
                                                                            <ul>
                                                                                {item.templateClauses.map(ayat => {
                                                                                    clauseItems = {
                                                                                        ...clauseItems,
                                                                                        clausal: [
                                                                                            ...clauseItems.clausal,
                                                                                            {
                                                                                                ID: ayat.ID,
                                                                                                clauseGroupID: ayat.templateClauseGroupID,
                                                                                                content: ayat.content,
                                                                                            },
                                                                                        ],
                                                                                    }
                                                                                    return (
                                                                                        <li>
                                                                                            <span
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: `${ayat.content}`,
                                                                                                }}></span>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        )}
                                                    </div>
                                                </Modal>
                                            </div>
                                        )
                                    })}
                                    <EmptyState action={contractContext.appendClauseGroup} />
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Modal
                    title='Pilih Template'
                    visible={isModalVisibleTemplate}
                    width={"85%"}
                    footer={null}
                    onCancel={() => setIsModalVisibleTemplate(false)}
                    className='modalDetailContract'>

                    <Row gutter={16}>

                        {
                            templates.isLoading ?
                                [1, 2].map(_ => (
                                    <Col span={8}>
                                        <Card
                                            style={{ width: 300, marginTop: 16 }}
                                            actions={[
                                                <CloudDownloadOutlined />
                                            ]}>
                                            <Skeleton loading={true}></Skeleton>
                                        </Card>
                                    </Col>
                                )) :
                                templates.items.map(template => (
                                    <Col span={8}>
                                        <Card
                                            title={template.name}
                                            style={{ marginTop: 16 }}
                                            actions={[
                                                <Popconfirm
                                                    title={`Apakah anda yakin akan menggunakan template ${template.name} ?`}
                                                    onConfirm={() => {
                                                        useTemplate(template.ID)
                                                    }}
                                                    okText="Yakin"
                                                    cancelText="Batal"
                                                >
                                                    <CloudDownloadOutlined />
                                                </Popconfirm>
                                                ,
                                            ]}>
                                            <Tree
                                                treeData={template.templateClause.map((templateClauseGroup, templateClauseGroupIndex) => ({
                                                    title: `${templateClauseGroupIndex + 1}. ${templateClauseGroup.title}`,
                                                    key: `${templateClauseGroup.ID}`,
                                                    children: templateClauseGroup.templateClauses.map((templateClause, templateClauseIndex) => ({
                                                        title: <div>
                                                            {`(${templateClauseIndex + 1}). `}
                                                            {templateClause.content}
                                                        </div>,
                                                        key: `${templateClauseGroup.ID}-${templateClause.ID}`,

                                                    }))
                                                }))}
                                            />
                                        </Card>
                                    </Col>
                                ))
                        }
                    </Row>
                </Modal>

                <Modal
                    title='Simpan Data Kesepakatan'
                    visible={isSubmitting}
                    width={"85%"}
                    className='modalDetailContract'>
                    <Progress percent={progress} />
                </Modal>

                {/* ACTION */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    <Button type='primary' onClick={() => submit()}>
                        {isSubmitting ? 'Loading...' : 'Update'}
                    </Button>
                </div>
            </div>
        </div>
    )
}


