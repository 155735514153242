import React from 'react'
import { useState, useEffect } from 'react'

import Layout from '../../Layout'
import Table from '../../Components/Table'
import API from '../../Services/API'

import { BeatLoader } from 'react-spinners'

import { Input, Button, Row, Col, Select, Tag, Space, Modal, Divider, message, Spin, Steps, Popover } from 'antd'
import {
	PlusOutlined,
	UserAddOutlined,
	DownloadOutlined,
	EyeOutlined,
	SyncOutlined,
	UnorderedListOutlined,
	EditOutlined,
	DeleteOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import moment from 'moment'

const { Step } = Steps;
const { Option } = Select
const { Search } = Input
const BASE_URL = process.env.REACT_APP_API_BASE_URL

const customDot = (dot, { status, index }) => (
	<Popover
		content={
			<span>
				step {index} status: {status}
			</span>
		}
	>
		{dot}
	</Popover>
)


const Contract = ({ history }) => {
	// const [parameters, setParameters] = useState({
	//   status: "UPCOMING",
	//   signing: 0,
	//   keyword: null,
	//   type: 2,
	//   deleted: 0,
	// });

	const [parameters, setParameters] = useState({
		status: null,
		signing: null,
		keyword: '',
		type: null,
		deleted: null,
		classificationType: null,
	})

	const [dataContract, setDataContract] = useState({
		isLoading: true,
		items: [],
	})

	const [detailContract, setDetailContract] = useState({
		isLoading: true,
		data: null,
	})

	const [modalOpenID, setModalOpenID] = useState(null)
	const [isDeleting, setIsDeleting] = useState(null)
	const [isExporting, setIsExporting] = useState(null)

	const [isPreview, setIsPreview] = useState(null)
	const [documentPreview, setDocumentPreview] = useState(null)

	const [showModalPreview, setShowModalPreview] = useState(false)
	const [deletedID, setDeletedID] = useState(null)

	const reset = () => {
		setParameters({
			// status: "UPCOMING",
			// signing: 0,
			keyword: '',
			// type: 2,
			status: null,
			signing: null,
			keyword: null,
			type: null,
			deleted: null,
			classificationType: null,
		})
	}

	const onSearch = value => setParameters(prevState => ({ ...prevState, keyword: value }))

	const handleChangeSigning = value => {
		setParameters(prevState => ({ ...prevState, signing: value }))
	}

	const handleChangeClasification = value => {
		setParameters(prevState => ({ ...prevState, classificationType: value }))
	}

	const handleChangeArchive = value => {
		setParameters(prevState => ({ ...prevState, deleted: value }))
	}

	const handleChangeStatus = value => {
		setParameters(prevState => ({ ...prevState, status: value }))
	}

	const handleChangeDocument = value => {
		setParameters(prevState => ({ ...prevState, type: value }))
	}

	const getListContract = () => {
		setDataContract(prev => ({ ...prev, isLoading: true }))
		API.contract.list(parameters).then(response => {
			try {
				if (response.code === 200) {
					setDataContract({
						isLoading: false,
						items: response.data.list,
					})
				}
			} catch (error) {
				return
			}
		})
	}

	const getDetailContract = () => {
		if (modalOpenID !== null) {
			API.contract.detail(modalOpenID).then(response => {
				if (response.code === 200) {
					setDetailContract({
						isLoading: false,
						data: response.data,
					})
				}
			})
		}
	}

	const deleteContract = (contractID, deleted) => {
		setIsDeleting(contractID)

		API.contract.archive(contractID, { deleted: deleted }).then(response => {
			if (response.code === 200) {
				setIsDeleting(null)
				getListContract()
			}
			setDeletedID(null)
		})
	}

	const exportFile = contractID => {
		setIsExporting(contractID)
		API.exportContract(contractID).then(response => {
			if (typeof response === 'undefined') {
				window.open(`${BASE_URL}/contract/${contractID}/export`, '_blank')
				setIsExporting(null)
			} else {
				setIsExporting(null)
				message.error(response.message)
			}
		})
	}

	const previewDocument = (contractID, typeLabel) => {
		setIsPreview(contractID)
		API.exportContract(contractID).then(response => {
			if (typeof response === 'undefined') {
				setDocumentPreview(`https://assets.dev.mitrasdudi.id/porting/contract/${contractID}/${typeLabel}.docx`)
				setIsPreview(null)
				setShowModalPreview(true)
			} else {
				setIsPreview(null)
				setDocumentPreview(null)
				message.error(response.message)
			}
		})
	}

	useEffect(() => {
		getDetailContract()
	}, [modalOpenID])

	useEffect(() => {
		getListContract()
	}, [parameters])

	return (
		<Layout>
			<div className='contract'>
				<h3>Kelola Daftar Kesepakatan</h3>
				<div className='contract__listContract'>
					<section className='contract__listContract__tableWraper'>
						<Search
							placeholder='Cari berdasarkan kata kunci'
							onSearch={onSearch}
							enterButton
							className='contract__listContract__tableWraper__search'
						/>
						<Button
							style={{ marginRight: 10, padding: '0 6px' }}
							size='middle'
							icon={<SyncOutlined />}
							title='Refresh'
							onClick={reset}></Button>

						<Select
							// defaultValue={0}
							style={{ width: 120 }}
							onChange={handleChangeArchive}
							style={{ marginRight: 10 }}
							value={parameters.deleted}>
							<Option value={null} disabled>
								-- By Archive --
							</Option>
							<Option value={0}>No Archive</Option>
							<Option value={1}>Archive</Option>
						</Select>

						<Select
							// defaultValue={0}
							style={{ width: 120 }}
							onChange={handleChangeSigning}
							style={{ marginRight: 10 }}
							value={parameters.signing}>
							<Option value={null} disabled>
								-- By TTD --
							</Option>
							<Option value={0}>Belum TTD</Option>
							<Option value={1}>Sudah TTD</Option>
						</Select>
						<Select
							// defaultValue={2}
							style={{ width: 120 }}
							onChange={handleChangeDocument}
							style={{ marginRight: 10 }}
							value={parameters.type}>
							<Option value={null} disabled>
								-- By Jenis Dokumen --
							</Option>
							<Option value={1}>MoU</Option>
							<Option value={2}>PKS</Option>
							<Option value={3}>IA</Option>
							<Option value={4}>MoA</Option>
							<Option value={5}>MoU & MoA</Option>
							<Option value={6}>Surat Pernyataan</Option>
						</Select>
						<Select
							// defaultValue={2}
							style={{ width: 120 }}
							onChange={handleChangeClasification}
							style={{ marginRight: 10 }}
							value={parameters.classificationType}>
							<Option value={null} disabled>
								-- By Klasifikasi --
							</Option>
							<Option value={1}>PTVP</Option>
							<Option value={2}>SMK</Option>
							<Option value={3}>SUSLAT</Option>
							<Option value={4}>TU</Option>
						</Select>
						<Select
							// defaultValue="UPCOMING"
							style={{ width: 120 }}
							onChange={handleChangeStatus}
							style={{ marginRight: 20 }}
							value={parameters.status}>
							<Option value={null} disabled>
								-- By Status --
							</Option>
							<Option value='ACTIVE'>Active</Option>
							<Option value='EXPIRED'>Expired</Option>
							<Option value='UPCOMING'>Upcoming</Option>
						</Select>
						<Button type='primary' size='middle' icon={<PlusOutlined />}>
							<Link to='/contract/create' style={{ color: '#fff' }}>
								Buat Kesepakatan
							</Link>
						</Button>
					</section>
					{
						dataContract.isLoading ?
							<center>
								<Spin size='large' />
							</center> :
							<Table
								loading={dataContract.isLoading}
								data={dataContract.items.map((item, index) => {
									const upcoming = moment().isBefore(moment(item.contractStart, 'YYYY-MM-DD'))
									const expired = moment().isAfter(moment(item.contractEnd, 'YYYY-MM-DD'))
									const active =
										moment().isBefore(moment(item.contractEnd, 'YYYY-MM-DD')) &&
										moment().isAfter(moment(item.contractStart, 'YYYY-MM-DD'))

									return {
										// key: "1",
										...item,
										created: `${moment(item.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')}`,
										diksi: item.contractSigners[0] ? item.contractSigners[0].companySigner.company.name : null,
										partner: item.contractSigners[1] ? item.contractSigners[1].companySigner.company.name : null,
										partnerType: item.contractSigners[1] ? item.contractSigners[1].companySigner.company.companyTypeLabel : null,
										partnerCountry: item.contractSigners[1] ? item.contractSigners[1].companySigner.company.country : null,
										no: index + 1,
										type: item.typeLabel,
										nameContract: item.name,
										tempat: item.location,
										directorName: item.cooperationDirector.name,
										tglAwal: `${moment(item.contractStart, 'YYYY-MM-DD').format('DD MMMM YYYY')}`,
										tglAkhir: `${moment(item.contractEnd, 'YYYY-MM-DD').format('DD MMMM YYYY')}`,
										diksiLink: (
											<Tag
												color={'blue'}
												style={{ cursor: 'pointer' }}
												onClick={async () => {
													await navigator.clipboard.writeText(
														`${window.location.origin}/assign?token=${item.contractSigners[0] ? item.contractSigners[0].token : ''}`
													)
													message.success('Sukses copy link')
												}}>
												Link
											</Tag>
										),
										partnerLink: (
											<Tag
												color={'blue'}
												style={{ cursor: 'pointer' }}
												onClick={async () => {
													await navigator.clipboard.writeText(
														`${window.location.origin}/assign?token=${item.contractSigners[1] ? item.contractSigners[1].token : ''}`
													)
													message.success('Sukses copy link')
												}}>
												Link
											</Tag>
										),
										contractStatus: upcoming ? (
											<Tag color='default'>Akan Datang </Tag>
										) : active ? (
											<Tag color='green'>Aktif</Tag>
										) : expired ? (
											<Tag color='error'>Kadaluwarsa</Tag>
										) : null,
										status:
											item.contractSigners.length === 1 ? (
												<Tag color={(item.contractSigners[0].companySigner ? item.contractSigners[0].companySigner.signing : null) === null ? 'red' : 'green'}>
													{(item.contractSigners[0].companySigner ? item.contractSigners[0].companySigner.signing : null) === null ? 'Belum TTD' : 'Assigned'}
												</Tag>
											) : item.contractSigners.length === 2 ? (
												<>
													<Tag color={(item.contractSigners[0].companySigner ? item.contractSigners[0].companySigner.signing : null) === null ? 'red' : 'green'}>
														{(item.contractSigners[0].companySigner ? item.contractSigners[0].companySigner.signing : null) === null ? 'Belum TTD' : 'Assigned'}
													</Tag>
													<Tag
														style={{ marginTop: 10 }}
														color={(item.contractSigners[1].companySigner ? item.contractSigners[1].companySigner.signing : null) === null ? 'red' : 'green'}>
														{(item.contractSigners[1].companySigner ? item.contractSigners[1].companySigner.signing : null) === null ? 'Belum TTD' : 'Assigned'}
													</Tag>
												</>
											) : (
												<Tag color='red'>No Assigned</Tag>
											),
										action: (
											<div style={{ flexDirection: 'column' }}>
												<div style={{ marginBottom: 10 }}>
													<Steps size={'small'} current={item.stage} progressDot direction="vertical" >
														<Step title="Isi Informasi Dasar" />
														<Step title="Konstruksi Pasal" />
														<Step title="Tanda Tangan" />
														<Step title="Isi Nomor" />
													</Steps>
												</div>
												<div>
													<Space size='small'>
														<Button
															type='default'
															icon={<UnorderedListOutlined />}
															title='Detail'
															size='small'
															onClick={() => setModalOpenID(item.ID)}></Button>
														<Button
															type='default'
															icon={isExporting === item.ID ? <SyncOutlined spin /> : <DownloadOutlined />}
															title='Download'
															onClick={() => exportFile(item.ID)}
															size='small'></Button>
														<Button
															type='default'
															icon={isPreview === item.ID ? <SyncOutlined spin /> : <EyeOutlined />}
															title='Preview Document'
															size='small'
															onClick={() => previewDocument(item.ID, item.typeLabel.replace(/\s/g, "%20"))}></Button>

														<Button
															type='default'
															icon={<EditOutlined />}
															title='Update'
															size='small'
															onClick={() => history.push(`/contract/${item.ID}`)}></Button>

														{item.deleted === 0 ? (
															<Button
																type='default'
																icon={isDeleting === item.ID ? <SyncOutlined spin /> : <DeleteOutlined />}
																title='Delete'
																size='small'
																onClick={() => setDeletedID(item.ID)}></Button>
														) : (
															<Button
																type='default'
																icon={isDeleting === item.ID ? <SyncOutlined spin /> : <SyncOutlined />}
																title='Rollback'
																size='small'
																onClick={() => deleteContract(item.ID, 0)}></Button>
														)}
													</Space>

												</div>
											</div>
										),
									}
								})}
							/>
					}
				</div>
			</div>
			<Modal
				title='Detail Kesepakatan'
				visible={modalOpenID !== null}
				onOk={() => setModalOpenID(null)}
				onCancel={() => setModalOpenID(null)}
				width={"85%"}
				className='modalDetailContract'>
				{
					detailContract.isLoading ?
						<BeatLoader size={7} color='blue' /> :
						<div>
							<Tag color='green'>{detailContract.data.type}</Tag>
							<p>{detailContract.data.name}</p>
							<Divider />
							<table style={{ width: '100%' }}>
								<tr>
									<td>Lokasi Kesepakatan</td>
									<td>:</td>
									<td>{detailContract.data.location}</td>
								</tr>
								<tr>
									<td>Tanggal Awal Kesepakatan</td>
									<td>:</td>
									<td>{`${moment(detailContract.data.contractStart, 'YYYY-MM-DD').format('DD-MM-YYYY')}`}</td>
								</tr>
								<tr>
									<td>Tanggal Akhir Kesepakatan</td>
									<td>:</td>
									<td>{`${moment(detailContract.data.contractEnd, 'YYYY-MM-DD').format('DD-MM-YYYY')}`}</td>
								</tr>
								{detailContract.data.contractSigners.length < 1
									? null
									: detailContract.data.contractSigners.map((signer, index) => {
										return (
											<>
												<tr>
													<td>Institusi {index + 1}</td>
													<td>:</td>
													<td>
														{signer.companySigner.company.name} ( <b>{signer.companySigner.officerName} </b>)
													</td>
												</tr>
												<tr>
													<td>TTD Institusi {index + 1}</td>
													<td>:</td>
													<td>
														{signer.signingContract === null ? (
															<>
																<Tag color='red'>Belum TTD</Tag>
																<Link to={`/assign/${signer.ID}`}>
																	<Button type='dashed' size='small' icon={<EditOutlined />}>
																		Tanda Tangan Sekarang
																	</Button>
																</Link>
															</>
														) : (
															<Tag
																color='green'
																style={{ cursor: 'pointer' }}
																onClick={() => window.open(`${signer.signingContract.urlSigning}`, '_blank')}>
																Lihat TTD
															</Tag>
														)}
													</td>
												</tr>
											</>
										)
									})}
							</table>
							<Divider />
							{detailContract.data.clauseGroups.length < 1
								? null
								: detailContract.data.clauseGroups.map((clause, index) => {
									return (
										<>
											<p>
												<b>
													Pasal {index + 1} - {clause.title}
												</b>
											</p>
											<ul>
												{clause.clausal.map(cluseItem => {
													return (
														<li>
															<span
																dangerouslySetInnerHTML={{
																	__html: `${cluseItem.content}`,
																}}></span>
															{/* {cluseItem.content} */}
														</li>
													)
												})}
											</ul>
										</>
									)
								})}
						</div>
				}
			</Modal>
			{/* Modal Preview DOc */}
			<Modal
				title='Preview Document'
				visible={showModalPreview}
				onCancel={() => setShowModalPreview(false)}
				footer={null}
				// width={"80%"}
				className='modalPreviewDocumentContract'>
				<iframe
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${documentPreview}`}
					width='100%'
					height='800px'
					frameborder='0'>
					Docs Preview
				</iframe>
			</Modal>
			{/* Modal Delete */}
			<Modal
				title='Modal Confirm'
				visible={deletedID !== null}
				onOk={() => deleteContract(deletedID, 1)}
				onCancel={() => setDeletedID(null)}
				cancelText='No'
				okText={'Ya'}>
				<p>Yakin ingin menghapus data ini ?</p>
			</Modal>
		</Layout>
	)
}

export default Contract
