import React from 'react'
import { createContext, useState, useEffect } from 'react'
import moment from 'moment'

export const AuthContext = createContext()
export const AuthContextConsumer = AuthContext.Consumer

const AuthContextProvider = ({ children }) => {
	const authenticationToken = localStorage.getItem('authenticationToken')

	const [userAccess, setUserAccess] = useState({token:null, access:null})
	const [contractIDCreated, setContractIDCreated] = useState(null)
	const [defaultInstitusi, setDefaultInstitusi] = useState(null)
	const [defaultInstitusiLast, setDefaultInstitusiLast] = useState(null)
	const [errorMessage, setErrorMessage] = useState({
		contract: '',
		assignFirst: {},
		assignSecound: {},
	})
	const [contractDataInput, setContractDataInput] = useState({
		type: null,
		name: null,
		location: null,
		classificationType: null,
		cooperationDirector: null,
		contractStart: moment().format('YYYY-MM-DD'),
		contractEnd: moment().format('YYYY-MM-DD'),
	})


	useEffect(() => {
		setUserAccess(previous => ({...previous, token: authenticationToken}))
	}, [authenticationToken])

	// ======================= Pasal =============================== //
	const [clausesGroup, setClausesGroup] = useState([
		{
			title: '',
			clauses: [''],
		},
	])

	// Pasal
	const addNewClauseGroup = () => {
		setClausesGroup(prevState => [
			...prevState,
			{
				title: '',
				clauses: [''],
			},
		])
	}

	const removeClauseGroup = index => {
		const newData = clausesGroup.filter((data, dataIndex) => dataIndex !== index)

		setClausesGroup(newData)
	}

	// Ayat
	const addNewClause = clauseIndexGroup => {
		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clauses: [...clausesGroupItems[clauseIndexGroup].clauses, ''],
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const removeClause = (clauseIndexGroup, index) => {
		const newData = clausesGroup[clauseIndexGroup].clauses.filter((data, dataIndex) => dataIndex !== index)

		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clauses: newData,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changePasal = (value, clauseIndexGroup) => {
		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			title: value,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changeClause = (value, clauseIndexGroup, index) => {
		let clausesGroupItemsClauses = [...clausesGroup[clauseIndexGroup].clauses]
		clausesGroupItemsClauses[index] = value

		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clauses: clausesGroupItemsClauses,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changePasalExtendTemplate = (data, clauseIndexGroup) => {
		let clausesGroupItems = [...clausesGroup]
		let clause = data
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	// ======================= Edn Of Pasal ========================= //

	const [firstInstitusi, setFirstInstitusi] = useState({
		companyName: 'Kementerian Pendidikan & Kebudayaan',
		companyAddress: 'Jalan Jenderal Sudirman, Senayan, Jakarta Pusat 10270',
		companyAbout:
			' Kementerian yang mempunyai tugas menyelenggarakan urusan pemerintahan di bidang pendidikan dan kebudayaan untuk membantu Presiden dalam menyelenggarakan pemerintahan negara',
		companyLogo: null,
		officerName: 'NADIEM ANWAR MAKARIM',
		officerPosition: 'Menteri Pendidikan dan Kebudayaan Republik Indonesia',
		contractNumber: null,
		countryID: null,
	})

	const [lastInstitusi, setLastInstitusi] = useState({
		companyName: null,
		companyAddress: null,
		companyAbout: null,
		companyLogo: null,
		officerName: null,
		officerPosition: null,
		contractNumber: null,
		companyType: null,
		countryID: null,
	})

	const isEmptyData = () => {
		if (
			(contractDataInput.type === null ||
				contractDataInput.name === null ||
				contractDataInput.location === null ||
				contractDataInput.contractStart === null ||
				contractDataInput.contractEnd === null ||
				contractDataInput.cooperationDirector === null ||
				contractDataInput.classificationType === null ||
				firstInstitusi.companyName === null ||
				firstInstitusi.companyAddress === null ||
				firstInstitusi.companyAddress === null ||
				firstInstitusi.companyAbout === null ||
				firstInstitusi.officerPosition === null ||
				firstInstitusi.contractNumber === null ||
				firstInstitusi.countryID === null ||
				(firstInstitusi.companyName !== 'Kementerian Pendidikan & Kebudayaan' && firstInstitusi.companyLogo) === null ||
				lastInstitusi.companyName === null ||
				lastInstitusi.companyAddress === null ||
				lastInstitusi.companyAddress === null ||
				lastInstitusi.companyAbout === null ||
				lastInstitusi.officerPosition === null ||
				lastInstitusi.companyType === null ||
				lastInstitusi.companyLogo === null,
			lastInstitusi.countryID === null)
		) {
			return true
		} else {
			return false
		}
	}

	const updateContractDataInput = parameters => {
		setContractDataInput(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	const changeDefaultInstitusi = value => {
		setDefaultInstitusi(value)
		if (value === null || value === 2) {
			setFirstInstitusi({
				companyName: null,
				companyAddress: null,
				companyAbout: null,
				companyLogo: null,
				officerName: null,
				officerPosition: null,
				contractNumber: null,
				countryID: null,
			})
		} else if (value === 1) {
			setFirstInstitusi({
				companyName: 'Kementerian Pendidikan & Kebudayaan',
				companyAddress: 'Jalan Jenderal Sudirman, Senayan, Jakarta Pusat 10270',
				companyAbout:
					' Kementerian yang mempunyai tugas menyelenggarakan urusan pemerintahan di bidang pendidikan dan kebudayaan untuk membantu Presiden dalam menyelenggarakan pemerintahan negara',
				companyLogo: null,
				officerName: 'NADIEM ANWAR MAKARIM',
				officerPosition: 'Menteri Pendidikan dan Kebudayaan Republik Indonesia',
				contractNumber: null,
				countryID: null,
			})
		}
	}

	const updateDataInputFirstInstitusi = parameters => {
		setFirstInstitusi(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	const updateDataInputLastInstitusi = parameters => {
		setLastInstitusi(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	const setDataErrorMessage = parameters => {
		setErrorMessage(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	const updateContractIDCreated = contractID => {
		setContractIDCreated(contractID)
	}

	const resetState = () => {
		setDefaultInstitusi(null)
		setErrorMessage({
			contract: '',
			assignFirst: {},
			assignSecound: {},
		})
		setContractDataInput({
			type: null,
			name: null,
			location: null,
			contractStart: moment().format('YYYY-MM-DD'),
			contractEnd: moment().format('YYYY-MM-DD'),
		})

		setClausesGroup([
			{
				title: '',
				clauses: [''],
			},
		])

		setFirstInstitusi({
			companyName: 'Kementerian Pendidikan & Kebudayaan',
			companyAddress: 'Jalan Jenderal Sudirman, Senayan, Jakarta Pusat 10270',
			companyAbout:
				' Kementerian yang mempunyai tugas menyelenggarakan urusan pemerintahan di bidang pendidikan dan kebudayaan untuk membantu Presiden dalam menyelenggarakan pemerintahan negara',
			companyLogo: null,
			officerName: 'NADIEM ANWAR MAKARIM',
			officerPosition: 'Menteri Pendidikan dan Kebudayaan Republik Indonesia',
			contractNumber: null,
		})

		setLastInstitusi({
			companyName: null,
			companyAddress: null,
			companyAbout: null,
			companyLogo: null,
			officerName: null,
			officerPosition: null,
			contractNumber: null,
		})
	}

	return (
		<AuthContext.Provider
			value={{
				contractDataInput: contractDataInput,
				updateContractDataInput: updateContractDataInput,
				isEmptyData: isEmptyData,
				defaultInstitusi: defaultInstitusi,
				changeDefaultInstitusi: changeDefaultInstitusi,
				updateDataInputFirstInstitusi: updateDataInputFirstInstitusi,
				updateDataInputLastInstitusi: updateDataInputLastInstitusi,
				lastInstitusi: lastInstitusi,
				firstInstitusi: firstInstitusi,
				errorMessage: errorMessage,
				setDataErrorMessage: setDataErrorMessage,
				clausesGroup: clausesGroup,
				addNewClauseGroup: addNewClauseGroup,
				removeClauseGroup: removeClauseGroup,
				setClausesGroup,
				addNewClause: addNewClause,
				removeClause: removeClause,
				changePasal: changePasal,
				changeClause: changeClause,
				changePasalExtendTemplate: changePasalExtendTemplate,
				contractIDCreated: contractIDCreated,
				setContractIDCreated: updateContractIDCreated,
				resetState: resetState,
				defaultInstitusiLast,
				setDefaultInstitusiLast,
				userAccess, setUserAccess
			}}>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
