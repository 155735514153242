import React from 'react'
import { Table, Avatar } from 'antd'

import { SwapOutlined } from '@ant-design/icons'

const columns = [
	{
		title: 'UID',
		dataIndex: 'ID',
		key: 'no',
		fixed: 'left',
		width: '48px',
	},
	{
		title: 'Jenis Dokumen',
		key: 'type',
		width: '120px',
		render: (contract, row, index) => {
			return (
				<div style={{ textAlign: 'center' }}>
					<b>{contract.typeLabel}</b><br />
					{contract.classificationLabel} <br />
					<span style={{ fontSize: 'x-small' }}>{contract.partnerType}</span>
				</div>
			)
		}
	},
	{
		title: 'Pihak',
		key: 'created',
		width: '200px',
		render: (contract, row, index) => {
			return (
				<div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					{
						contract.contractSigners.length < 1 ? null :
							<div style={{ marginRight: 10, marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
								<Avatar size="large" src={contract.contractSigners[0].companySigner.company.urlLogo} />
								<h4>{contract.contractSigners[0].companySigner.company.name}</h4>
							</div>
					}
					<div style={{ marginRight: 10, marginLeft: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<SwapOutlined />
					</div>
					{
						contract.contractSigners.length < 2 ? null :
							<div style={{ marginRight: 10, marginLeft: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
								<Avatar size="large" src={contract.contractSigners[1].companySigner.company.urlLogo} />
								<h4>{contract.contractSigners[1].companySigner.company.name}</h4>
							</div>
					}
				</div>
			)
		}
	},
	{
		title: 'Nama',
		key: 'nameContract',
		width: '200px',
		render: (contract, row, index) => {
			return (
				<div style={{ textAlign: 'center' }}>
					<b>{contract.nameContract}</b> <br />
				</div>
			)
		},
	},
	{
		title: 'Tempat dan Tanggal',
		key: 'tempat',
		render: (contract, row, index) => {
			return (
				<div style={{ textAlign: 'center' }}>
					<b>{contract.tempat}</b> <br />
					{contract.tglAwal} - {contract.tglAkhir}
				</div>
			)
		},
		width: '200px',
	},

	{
		title: 'Negara Partner',
		dataIndex: 'partnerCountry',
		key: 'partnerCountry',
		width: '200px',
	},
	{
		title: 'Status Kerja Sama',
		dataIndex: 'contractStatus',
		key: 'contractStatus',
		width: '200px',
	},
	{
		title: 'Pembina Kerja Sama',
		dataIndex: 'directorName',
		key: 'directorName',
		width: '200px',
	},
	{
		title: 'Status TTD',
		dataIndex: 'status',
		key: 'status',
		width: '200px',
	},
	{
		title: 'Link TTD Diksi',
		dataIndex: 'diksiLink',
		key: 'diksiLink',
		width: '160px',
	},
	{
		title: 'Link TTD Partner',
		dataIndex: 'partnerLink',
		key: 'partnerLink',
		width: '160px',
	},
	{
		title: 'Action',
		key: 'action',
		dataIndex: 'action',
		fixed: 'right',
		width: 200,
	},
]

const TableComponent = ({ data, loading }) => {
	return (
		<Table columns={columns} dataSource={data} size='small' scroll={{ x: 1500 }} bordered sticky loading={loading} />
	)
}

export default TableComponent
