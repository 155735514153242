import Authentication from "../Authentication"

export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export default class BaseAPI {
    static fetch = async (uri, config) => {
        const defaultConfig = {
            headers: {
                Role: 'Contract-Operator',
                Accept: 'application/json',
            },
        }

        const token = await Authentication.getToken()

        if (token) {
            defaultConfig.headers['Authorization'] = `Bearer ${token}`
        }

        const mergeConfig = { ...defaultConfig, ...config }
        let bodyConfig = {}
        if (mergeConfig.body && !(mergeConfig.body instanceof FormData)) {
            const form = new FormData()
            Object.entries(mergeConfig.body).map(([label, value]) => {
                if (Array.isArray(value)) {
                    value.map((each, index) => {
                        if (typeof each === 'object') {
                            Object.keys(each).map(key => {
                                form.append(`${label}[${index}][${key}]`, each[key])
                                return true
                            })
                        } else {
                            form.append(`${label}[]`, each)
                        }
                        return true
                    })
                } else {
                    form.append(label, value)
                }

                return true
            })
            bodyConfig = { body: form }
        }
        const cleanConfig = { ...mergeConfig, ...bodyConfig }
        const url = `${BASE_URL}/${uri}`
        return fetch(url, cleanConfig)
            .then(res => {
                if (res.status !== 200) {
                    if (this.component) {
                        let { errorAPI } = this.component.state
                        if (!errorAPI) {
                            errorAPI = new Map()
                        }
                        errorAPI.set(uri, res.statusText)
                        this.component.setState({ errorAPI: errorAPI })
                    }
                }
                return res.json()
            })
            .catch(err => {
            })
    }

    static cleanPayload = payload => {
        Object.keys(payload).forEach(key => payload[key] == null && delete payload[key])
        return payload
    }
}