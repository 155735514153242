import React from 'react'
import { useState, useContext } from 'react'
import Layout from '../../Layout'
import Table from '../../Components/Table'
import StepOne from './Steps/StepOne'
import StepTwo from './Steps/StepsTwo'
import StepThree from './Steps/StepThree'
import { BeatLoader } from 'react-spinners'
import API from '../../Services/API'

import { AuthContext } from '../../Context/AuthContext'
import { useHistory } from 'react-router-dom'

import { Button, Steps, message, Modal } from 'antd'

const { Step } = Steps

const CreateContract = () => {
	const history = useHistory()
	const authContext = useContext(AuthContext)

	const [current, setCurrent] = useState(0)
	const [isModalShow, setIsModalShow] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)


	const submit = () => {
		setIsSubmitting(true)
		API.contract.create(authContext.contractDataInput).then(response => {
			if (response.code === 200) {
				authContext.setContractIDCreated(response.data.ID)
				// First
				API.assignSignerContract(response.data.ID, authContext.firstInstitusi).then(response => {
					if (response.code === 200) {
						authContext.setDataErrorMessage({
							assignFirst: {},
						})
						// Last
						API.assignSignerContract(response.data.ID, authContext.lastInstitusi).then(response => {
							if (response.code === 200) {
								setIsSubmitting(false)
								authContext.setDataErrorMessage({
									contract: '',
									assignFirst: {},
									assignSecound: {},
								})
								history.push(`/contract/${response.data.ID}`)
							} else {
								authContext.setDataErrorMessage({
									assignSecound: response.errors,
								})
								setIsSubmitting(false)
							}
							setIsModalShow(false)
						})
					} else {
						authContext.setDataErrorMessage({
							assignFirst: response.errors,
						})
						setIsModalShow(false)
						setIsSubmitting(false)
					}
				})
			} else {
				authContext.setDataErrorMessage({
					contract: 'Terjadi Kesalahan Pada Saat Buat Kesepakatan',
				})
			}
		})
	}


	return (
		<Layout>
			<div className='contract' style={{ marginBottom: 24 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 10,
					}}>
					<h3>Input Kesepakatan Baru</h3>
					<Button onClick={() => window.location.reload()}>Refresh</Button>
				</div>
				<div style={{ background: '#fff', padding: 20 }}>
					<StepOne />
					<Button
						type='primary'
						onClick={() => {
							setIsModalShow(true)
						}}>
						Selesai
					</Button>
				</div>
			</div>
			<Modal
				title='Modal Confirm'
				visible={isModalShow}
				onOk={submit}
				onCancel={() => setIsModalShow(false)}
				cancelText='No'
				okText={isSubmitting ? 'Loading...' : 'Ya'}>
				<p>Pastikan Data Anda Benar, Ingin Melanjutkan Ke Step Berikutnya ?</p>
			</Modal>
		</Layout>
	)
}

export default CreateContract
