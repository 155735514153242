import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { Tag, Divider } from 'antd'
import API from '../../../Services/API'
import { message, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'

import moment from 'moment'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const StepThree = () => {
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const [detailContract, setDetailContract] = useState({
    isLoading: true,
    data: null,
  })

  const fetchDetailContract = () => {
    API.contract.detail(authContext.contractIDCreated).then(response => {
      if (response.code === 200) {
        authContext.setContractIDCreated(null)
        setDetailContract({
          isLoading: false,
          data: response.data,
        })
      }
    })
  }

  useEffect(() => {
    fetchDetailContract()
  }, [])

  return (
    <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10 }}>
      {detailContract.isLoading ? (
        <Spin indicator={antIcon} />
      ) : (
        <>
          <p>
            KERJA SAMA ANTARA <b>{detailContract.data && detailContract.data.contractSigners[0] && detailContract.data.contractSigners[0].companySigner && detailContract.data.contractSigners[0].companySigner.company && detailContract.data.contractSigners[0].companySigner.company.name ? detailContract.data.contractSigners[0].companySigner.company.name : '-'}</b> DAN{' '}
            <b>{detailContract.data && detailContract.data.contractSigners[1] && detailContract.data.contractSigners[1].companySigner && detailContract.data.contractSigners[1].companySigner.company && detailContract.data.contractSigners[1].companySigner.company.name ? detailContract.data.contractSigners[1].companySigner.company.name : '-'}</b>
          </p>
          <Divider />
          <table width='100%'>
            <tr>
              <td style={{ padding: '10px 0' }}>No Kesepakatan ( Diksi )</td>
              <td>:</td>
              <td>
                <Tag color='green'>{detailContract.data && detailContract.data.contractSigners[0] && detailContract.data.contractSigners[0].contractNumber ? detailContract.data.contractSigners[0].contractNumber : '-'}</Tag>
              </td>
            </tr>

            <tr>
              <td style={{ padding: '10px 0' }}>No Kesepakatan ( Partner)</td>
              <td>:</td>
              <td>
                <Tag color='green'>{detailContract.data && detailContract.data.contractSigners[1] && detailContract.data.contractSigners[1].contractNumber ? detailContract.data.contractSigners[1].contractNumber : '-'}</Tag>
              </td>
            </tr>

            <tr>
              <td>Negara Partner </td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.contractSigners[1] && detailContract.data.contractSigners[1].companySigner && detailContract.data.contractSigners[1].companySigner.company && detailContract.data.contractSigners[1].companySigner.company.country ? detailContract.data.contractSigners[1].companySigner.company.country : '-'}</td>
            </tr>

            <tr>
              <td>Jenis Partner </td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.contractSigners[1] && detailContract.data.contractSigners[1].companySigner && detailContract.data.contractSigners[1].companySigner.company && detailContract.data.contractSigners[1].companySigner.company.companyTypeLabel ? detailContract.data.contractSigners[1].companySigner.company.companyTypeLabel : '-'}</td>
            </tr>

            <tr>
              <td style={{ padding: '10px 0' }}>Tanggal Awal Kesepakatan</td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.contractStart ? moment(detailContract.data.contractStart, 'YYYY-MM-DD').format('DD-YYY-DD') : '-'}</td>
            </tr>

            <tr>
              <td style={{ padding: '10px 0' }}>Tanggal Akhir Kesepakatan</td>
              <td>:</td>
              <td> {detailContract.data && detailContract.data.contractEnd ? moment(detailContract.data.contractEnd, 'YYYY-MM-DD').format('DD-YYY-DD') : '-'}</td>
            </tr>

            <tr>
              <td>Tempat </td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.location ? detailContract.data.location : '-'}</td>
            </tr>

            <tr>
              <td>Klasifikasi </td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.classificationLabel ? detailContract.data.classificationLabel : '-'}</td>
            </tr>

            <tr>
              <td>Jenis Dokumen </td>
              <td>:</td>
              <td>{detailContract.data && detailContract.data.typeLabel ? detailContract.data.typeLabel : '-'}</td>
            </tr>
          </table>
          <p style={{ marginTop: 20 }}>
            Untuk Melihat Lebih Detail, Silahkan klik Tombol Done dan Lihat di Daftar Kesepakatan !
          </p>
        </>
      )}
    </div>
  )
}

export default StepThree
