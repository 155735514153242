import React from 'react'
import { useState } from "react";
import { Row, Col, Space, Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import IllustrationRight from "../../Assets/illustration-right.png";
import Logo from "../../Assets/logo.png";
import { BeatLoader } from "react-spinners";
import Authentication from "../../Services/Authentication";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const style = {
    background: "#F0F5F9",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    padding: 30,
    justifyContent: "space-between",
    flexDirection: "column",
  };

  const onFinish = (values) => {
    setIsSubmitting(true);

    Authentication.login(values.username, values.password).then((response) => {
      if (response.code === 200) {
        history.push("/");
      } else {
        setErrorMessage(response.message || "");
        setIsSubmitting(false)
      }
    });
  };

  return (
    <Row className="login">
      <Col
        xs={24}
        // sm={24}
        md={14}
        lg={14}
        style={style}
        className="login__left"
      >
        {/* <img src={IllustrationRight} style={{ marginTop: 40 }} /> */}
        <p>Komat, Komit biar komit</p>
        <h1>KOMIT MITRASDUDI</h1>
        {/* <p>
          Copyright 2021 By <br />
          Mitrasdudi
        </p> */}
      </Col>
      <Col
        xs={24}
        // sm={24}
        md={10}
        lg={10}
        style={{ ...style, background: "#fff", justifyContent: "center" }}
        className="p-10 login__right"
      >
        <div>
          <img src={Logo} />
          <p style={{ marginBottom: 0 }}><b>KOMIT</b></p>
          <p>Sistem Informasi Kesepakatan<br/>atau Kerja Sama</p>
        </div>
        <h2>Welcome Back !</h2>

        <label style={{ color: "red", marginBottom: 10 }}>
          {" "}
          {errorMessage ? `${errorMessage} !` : null}
        </label>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {/* <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password
            </a>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {isSubmitting ? <BeatLoader size={5} color="#fff" /> : "LOGIN"}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
