import React from 'react'
import { useState, useEffect, useCallback, useContext } from 'react'
import API from '../../../../Services/API'

import { Input, Button, Row, Col, Form, DatePicker, Select, Avatar, message, Spin } from 'antd'
import debounce from 'lodash.debounce'

import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { ContractEditorContext } from '../../../../Context/ContractEditorContext'

const { TextArea } = Input
const { RangePicker } = DatePicker
const { Option } = Select

const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Please select time!',
        },
    ],
}

const AVATAR_SIZE = '145px'

export default () => {
    const { id } = useParams()
    const contractID = id
    const history = useHistory();

    const contractContext = useContext(ContractEditorContext)

    const [countryData, setCountryData] = useState([])
    const [isFetchingCountry, setIsFetchingCountry] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const fetchCountry = useCallback(
        debounce(async value => {
            if (!isFetchingCountry) {
                setIsFetchingCountry(true)
                const response = await API.getCountryData({ keyword: value })
                setCountryData(response.data.list)
                setIsFetchingCountry(false)
            }
        }, 500)
    )

    useEffect(() => fetchCountry(''), [])

    const updateFormFirstParty = parameters => contractContext.setFormFirstParty(prevState => ({ ...prevState, ...parameters }))
    const updateFormSecondParty = parameters => contractContext.setFormSecondParty(prevState => ({ ...prevState, ...parameters }))

    const submit = async () => {
        setIsSubmitting(true)
        await API.contract.update(contractID, contractContext.formContract).then(response => {
            const responseDataContract = response

            if (response.code === 200) {
                if (response.data.contractSigners.length > 0) {
                    // First
                    API.assignSignerContractUpdate(response.data.contractSigners[0].ID, contractContext.formFirstParty).then(response => {
                        if (response.code === 200) {
                            // Last
                            if (responseDataContract.data.contractSigners.length === 2) {
                                API.assignSignerContractUpdate(responseDataContract.data.contractSigners[1].ID, contractContext.formSecondParty).then(
                                    response => {
                                        if (response.code === 200) {
                                            setIsSubmitting(false)
                                        } else {
                                            setIsSubmitting(false)
                                        }
                                    }
                                )
                            }
                        } else {
                            setIsSubmitting(false)
                        }
                    })
                }
            } else {
                message.info('Terjadi Kesalahan Pada Saat Buat Kesepakatan')
            }
        })
        message.success('Kesepakatan Berhasil Di Update!')
    }

    return (
        <div>
            <div className='contract' style={{ marginBottom: 24 }}>
                <h3>Informasi Dasar Kesepakatan</h3>
            </div>
            <div style={{ background: '#fff', padding: 20 }}>
                <Row>
                    <Col xs={24} md={24} lg={24} style={{ marginRight: 50 }}>
                        <Form layout='vertical' size='small'>
                            <Form.Item label='Jenis Document'>
                                <Select
                                    style={{ width: 120 }}
                                    style={{ marginRight: 10 }}
                                    value={contractContext.formContract.type}
                                    onChange={value => {
                                        contractContext.setFormContract(prevState => ({
                                            ...prevState,
                                            type: value,
                                        }))
                                    }}>
                                    <Option value={null} disabled>
                                        --Pilih Jenis Document--
                                    </Option>
                                    <Option value={1}>MoU</Option>
                                    <Option value={2}>PKS</Option>
                                    <Option value={3}>IA</Option>
                                    <Option value={4}>MoA</Option>
                                    <Option value={5}>MoU & MoA</Option>
                                    <Option value={6}>Surat Pernyataan</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label='Nama Perjanjian'>
                                <Input
                                    placeholder='Nama Perjanjian'
                                    value={contractContext.formContract.name}
                                    onChange={({ target: { value } }) =>
                                        contractContext.setFormContract(prevState => ({
                                            ...prevState,
                                            name: value,
                                        }))
                                    }
                                />
                            </Form.Item>

                            <Form.Item label='Tempat Perjanjian'>
                                <Input
                                    placeholder='Tempat'
                                    value={contractContext.formContract.location}
                                    onChange={({ target: { value } }) => {
                                        contractContext.setFormContract(prevState => ({
                                            ...prevState,
                                            location: value,
                                        }))
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Tanggal Kerja Sama' {...rangeConfig}>
                                <RangePicker
                                    style={{ width: '100%' }}
                                    value={[
                                        moment(contractContext.formContract.contractStart, 'YYYY-MM-DD'),
                                        moment(contractContext.formContract.contractEnd, 'YYYY-MM-DD'),
                                    ]}
                                    onChange={date =>
                                        contractContext.setFormContract(prevState => ({
                                            ...prevState,
                                            contractStart: date[0].format('YYYY-MM-DD'),
                                            contractEnd: date[1].format('YYYY-MM-DD'),
                                        }))
                                    }
                                />
                            </Form.Item>
                        </Form>

                    </Col>
                </Row>
            </div>

            <div className='contract' style={{ marginBottom: 24, marginTop: 24 }}>
                <h3>Informasi Pemangku Kepentingan</h3>
            </div>
            <div style={{ background: '#fff', padding: 20 }}>
                <Row>
                    <Col xs={24} md={12} lg={12} style={{ paddingRight: 12, paddingLeft: 12 }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            {
                                contractContext.contract.isLoading || contractContext.contract.data.contractSigners.length < 1 ? null :
                                    <Avatar size="large" style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} src={contractContext.contract.data.contractSigners[0].companySigner.company.urlLogo} />

                            }
                        </div>
                        <Form layout='vertical' size='small'>
                            <Form.Item label='Pihak 1'>
                                {contractContext.formFirstParty.companyName === 'Kementerian Pendidikan & Kebudayaan' ? (
                                    <>
                                      
                                        <p>Logo :</p>
                                        <input
                                            type='file'
                                            name='firstFile'
                                            onChange={({ target: { files } }) => {
                                                updateFormFirstParty({
                                                    companyLogo: files[0],
                                                })
                                            }}
                                        />
                                        <span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            placeholder='Nama Institusi 1'
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.companyName}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    companyName: value,
                                                })
                                            }}
                                        />
                                        <Input
                                            placeholder='No Kesepakatan Institusi 1'
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.contractNumber}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    contractNumber: value,
                                                })
                                            }}
                                        />
                                        <TextArea
                                            placeholder='Alamat'
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.companyAddress}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    companyAddress: value,
                                                })
                                            }}></TextArea>
                                        <TextArea
                                            placeholder='About'
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.companyAbout}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    companyAbout: value,
                                                })
                                            }}></TextArea>
                                        <Input
                                            placeholder='Officer Name / PIC'
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.officerName}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    officerName: value,
                                                })
                                            }}
                                        />
                                        <Input
                                            placeholder='Jabatan '
                                            style={{ marginBottom: 10 }}
                                            value={contractContext.formFirstParty.officerPosition}
                                            onChange={({ target: { value } }) => {
                                                updateFormFirstParty({
                                                    officerPosition: value,
                                                })
                                            }}
                                        />
                                        <p>Logo :</p>
                                        <input
                                            type='file' w
                                            name='firstFile'
                                            onChange={({ target: { files } }) => {
                                                updateFormFirstParty({
                                                    companyLogo: files[0],
                                                })
                                            }}
                                        />
                                        <span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
                                    </>
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ paddingRight: 12, paddingLeft: 12 }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                            {
                                contractContext.contract.isLoading || contractContext.contract.data.contractSigners.length < 2 ? null :
                                    <Avatar size="large" style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} src={contractContext.contract.data.contractSigners[1].companySigner.company.urlLogo} />

                            }
                        </div>
                        <Form layout='vertical' size='small'>
                            <Form.Item label='Pihak 2'>
                                <Input
                                    placeholder='Nama Partner'
                                    style={{ marginBottom: 10 }}
                                    value={contractContext.formSecondParty.companyName}
                                    onChange={({ target: { value } }) => {
                                        updateFormSecondParty({
                                            companyName: value,
                                        })
                                    }}
                                />
                                <TextArea
                                    placeholder='Alamat'
                                    style={{ marginBottom: 10 }}
                                    value={contractContext.formSecondParty.companyAddress}
                                    onChange={({ target: { value } }) => {
                                        updateFormSecondParty({
                                            companyAddress: value,
                                        })
                                    }}></TextArea>
                                <Select
                                    showSearch
                                    value={contractContext.formSecondParty.countryID}
                                    placeholder='Pilih Negara'
                                    notFoundContent={isFetchingCountry ? <Spin size='small' /> : null}
                                    filterOption={false}
                                    onSearch={fetchCountry}
                                    onChange={value => {
                                        setIsFetchingCountry(false)
                                        updateFormSecondParty({
                                            countryID: value,
                                        })
                                    }}
                                    style={{ marginBottom: 10 }}>
                                    {countryData.map(d => {
                                        return (
                                            <Option key={d.ID} value={d.ID}>
                                                {d.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                                <TextArea
                                    placeholder='About'
                                    style={{ marginBottom: 10 }}
                                    value={contractContext.formSecondParty.companyAbout}
                                    onChange={({ target: { value } }) => {
                                        updateFormSecondParty({
                                            companyAbout: value,
                                        })
                                    }}></TextArea>
                                <Input
                                    placeholder='Officer Name / PIC'
                                    style={{ marginBottom: 10 }}
                                    value={contractContext.formSecondParty.officerName}
                                    onChange={({ target: { value } }) => {
                                        updateFormSecondParty({
                                            officerName: value,
                                        })
                                    }}
                                />
                                <Input
                                    placeholder='Jabatan '
                                    style={{ marginBottom: 10 }}
                                    value={contractContext.formSecondParty.officerPosition}
                                    onChange={({ target: { value } }) => {
                                        updateFormSecondParty({
                                            officerPosition: value,
                                        })
                                    }}
                                />
                                <p>Logo :</p>
                                <input
                                    type='file'
                                    name='lastFile'
                                    onChange={({ target: { files } }) => {
                                        updateFormSecondParty({
                                            companyLogo: files[0],
                                        })
                                    }}
                                />
                                <span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>


            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10, marginTop: 22 }}>
                <Button type='primary' disabled={isSubmitting} onClick={() => submit()}>
                    {isSubmitting ? 'Loading...' : 'Update'}
                </Button>
            </div>
        </div>
    )
}