import React, { useContext } from "react";
import { AuthContext } from "../../../../Context/AuthContext";
import { Input, Button, Form } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FileAddOutlined, FolderAddOutlined } from "@ant-design/icons";

const Quill = ReactQuill.Quill;
let Font = Quill.import("formats/font");
Font.whitelist = ["TimesNewRoman", "Arial"];
Quill.register(Font, true);

const EmptyStatePasal = ({ action = null }) => {
  const authContext = useContext(AuthContext);
  const modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      ["bold", "italic", "underline"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "indent",
  ];

  return (
    <div className="emptyStateContract">
      <div className="emptyStateWrapper">
        <div
          className="buttonAddPasal"
          onClick={action === null ? authContext.addNewClauseGroup : action}
        >
          Tambah Pasal
        </div>
      </div>
      <Form layout="vertical" size="small" className="formEmpty">
        <Form.Item
          rules={[
            {
              required: true,
              message: "Mohon Di Inputkan!",
            },
          ]}
        >
          <>
            <p>Judul Pasal</p>
            <div className="stepOneCreateContract">
              <Input
                placeholder="Title Pasal"
                style={{ marginRight: 20 }}
                disabled
              />
              <Button
                icon={<FileAddOutlined />}
                style={{ marginRight: 10 }}
                disabled
              >
                Pilih Template
              </Button>
            </div>
            <div className="stepOneCreateContractClause">
              <>
                <div className="stepOneCreateContractClause__item">
                  <ReactQuill
                    modules={modules}
                    formats={formats}
                    placeholder={"Ayat Pasal..."}
                    className="clause-container"
                    theme="snow"
                    value={null}
                    defaultValue={null}
                    readOnly
                  />
                  <Button
                    icon={<FolderAddOutlined />}
                    style={{ marginRight: 10 }}
                    disabled
                  >
                    Tambah Ayat
                  </Button>
                  <Button
                    icon={<FileAddOutlined />}
                    style={{ marginRight: 10 }}
                    disabled
                  >
                    Pilih Template
                  </Button>
                </div>
              </>
            </div>
          </>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmptyStatePasal;
