import React from 'react'
import { useState, useContext, useEffect, Fragment } from 'react'
import moment from 'moment'
import Layout from '../../../Layout'
import EditorInformation from './Steps/ContractEditorInformation'
import EditorClause from './Steps/ContractEditorClause'
import EditorSignature from './Steps/ContractEditorSignature'
import EditorNumber from './Steps/ContractEditorNumber'
import API from '../../../Services/API'
import { ContractEditorContext } from '../../../Context/ContractEditorContext'

import { AuthContext } from '../../../Context/AuthContext'
import { Link, useHistory } from 'react-router-dom'

import { Button, Steps, message, Popover, Modal, Tooltip, Progress } from 'antd'
import {
	EyeOutlined,
	SyncOutlined,
} from '@ant-design/icons'

const { Step } = Steps

const STEP_INFORMATION = 0;
const STEP_CLAUSE = 1;
const STEP_SIGNATURE = 2;
const STEP_ASSIGN_NUMBER = 3;

const Editor = ({ match }) => {
	const history = useHistory()
	const authContext = useContext(AuthContext)
	const contractEditorContext = useContext(ContractEditorContext)
	const { contract } = contractEditorContext

	const [current, setCurrent] = useState(STEP_INFORMATION)
	const [welcomingMessage, setWelcomingMessage] = useState(null)

	const next = () => setCurrent(current + 1)
	const prev = () => setCurrent(current - 1)

	const [isPreview, setIsPreview] = useState(null)
	const [documentPreview, setDocumentPreview] = useState(null)
	const [showModalPreview, setShowModalPreview] = useState(false)

	const previewDocument = (contractID, typeLabel) => {
		setIsPreview(contractID)
		API.exportContract(contractID).then(response => {
			if (typeof response === 'undefined') {
				setDocumentPreview(`https://assets.dev.mitrasdudi.id/porting/contract/${contractID}/${typeLabel}.docx?rev=${ Date.now()}`)
				setIsPreview(null)
				setShowModalPreview(true)
			} else {
				setIsPreview(null)
				setDocumentPreview(null)
				message.error(response.message)
			}
		})
	}


	const stage= contractEditorContext.contract.data ? contractEditorContext.contract.data.stage : null
	useEffect(() => {
		if (contractEditorContext.contract.data) {
			setCurrent((contractEditorContext.contract.data.stage + 1) < 3 ? (contractEditorContext.contract.data.stage + 1) : 3)
			setWelcomingMessage('Proses terakhir ada di sini')
			setTimeout(() => {
				setWelcomingMessage(null)
			}, 1500)
		}
	}, [stage])

	const steps = [
		{
			title: 'Informasi Dasar',
			content: <EditorInformation />
		},
		{
			title: 'Pasal',
			content: <EditorClause />,
			description: (
				<div>
					{
						contractEditorContext.contract.isLoading ? null :
							`${contractEditorContext.contract.data.clauseGroups.length} pasal`}
				</div>
			)
		},
		{
			title: 'Tanda Tangan',
			content: <EditorSignature />
		},
		{
			title: 'Nomor Akhir',
			content: <EditorNumber />,
			description: (
				<div>
					{
						contractEditorContext.contract.isLoading ? null :
							<Progress percent={(contractEditorContext.contract.data.contractSigners.filter(contractSigner => contractSigner.signingContract !== null).length / 2) * 100} />
					}
				</div>
			)
		},
	]

	return (
		<Layout>
			<div className='contract' style={{ marginBottom: 24 }}>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 10,
					}}>
					<div style={{ flexDirection: 'row', display: 'flex', width: '110%' }}>
						<h3 style={{ lineHeight: '15px', flex: 1 }}>
							{contract.isLoading ? 'Loading' : contract.isNewRecord ? 'Buat Kesepakatan Baru' : `Kesepakatan: ${contract.data.name}`} <br />
							<small>UID {contract.isLoading ? 'Loading' : contract.isNewRecord ? '' : `${contract.data.ID}`}</small>
						</h3>
						<h5 style={{ lineHeight: '15px', textAlign: 'right', marginRight: 10 }}>
							<Popover content={contract.isLoading ? '' : `Dibuat pada tanggal ${moment(contract.data.created, 'YYYY-MM-DD HH:mm:ss').format('dddd, D MMM YYYY')} pada pukul ${moment(contract.data.created, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}`} trigger="hover">
								Dibuat <small>{contract.isLoading ? 'Loading' : contract.isNewRecord ? '-' : moment(contract.data.created, 'YYYY-MM-DD HH:mm:ss').fromNow()}</small><br />
							</Popover>
							<Popover content={contract.isLoading ? '' : `Diperbaharui pada tanggal ${moment(contract.data.updated, 'YYYY-MM-DD HH:mm:ss').format('dddd, D MMM YYYY')} pada pukul ${moment(contract.data.updated, 'YYYY-MM-DD HH:mm:ss').format('H:mm')}`} trigger="hover">
								Diperbaharui <small> {contract.isLoading ? 'Loading' : contract.isNewRecord ? '-' : moment(contract.data.updated, 'YYYY-MM-DD HH:mm:ss').fromNow()}</small>
							</Popover>
						</h5>
					</div>
					<Button loading={contract.isLoading} onClick={() => contractEditorContext.fetchContract()}>Refresh</Button>
					<Button style={{ marginLeft: 5 }}
						icon={isPreview === contract.ID ? <SyncOutlined spin /> : <EyeOutlined />}
						onClick={() => previewDocument(contract.data.ID, contract.data.typeLabel.replace(/\s/g, "%20"))}>
						Pratinjau
					</Button>
				</div>
				<div style={{ background: '#fff', padding: 20 }}>
					<Fragment>
						<Steps current={current}>
							{steps.map((item, index) => (
								<Step
									key={item.title}
									description={item.description}
									title={(
										<Tooltip placement="topRight" title={welcomingMessage} visible={index == current && welcomingMessage}>
											{item.title}
										</Tooltip>
									)}
									status={item.status}
									onClick={() => setCurrent(index)} className={index == current ? 'selected' : ''}

								/>
							))}
						</Steps>
						<div className='steps-content'>{steps[current].content}</div>

					</Fragment>
				</div>
			</div>
			<Modal
				title='Preview Document'
				visible={showModalPreview}
				onCancel={() => setShowModalPreview(false)}
				footer={null}
				width={"80%"}
				className='modalPreviewDocumentContract'>
				<iframe
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${documentPreview}`}
					width='100%'
					height='800px'
					frameborder='0'>
					Docs Preview
				</iframe>
			</Modal>
		</Layout>

	)
}

export default Editor
