import React from 'react'
import { Chart } from 'react-google-charts'

const PieChart = ({ title, data }) => {
	return (
		<div className={'my-pretty-chart-container'} style={{ marginTop: 20 }}>
			<Chart
				chartType='PieChart'
				// width={"400px"}
				// height={'100%'}
				loader={<div style={{ textAlign: 'center', fontSize: 12 }}>Loading Chart...</div>}
				data={data}
				options={{
					title: title,
				}}
				rootProps={{ 'data-testid': '1' }}
			/>
		</div>
	)
}

export default PieChart
