import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import API from '../../Services/API'
import Layout from '../../Layout'
import EmptyState from './Steps/EmptyState'

import { Input, Button, Row, Col, Form, DatePicker, Select, Divider, Modal, message, Spin } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FileAddOutlined, DeleteOutlined, AudioOutlined, FolderAddOutlined } from '@ant-design/icons'
import debounce from 'lodash.debounce'

import moment from 'moment'
const Quill = ReactQuill.Quill
let Font = Quill.import('formats/font')
Font.whitelist = ['TimesNewRoman', 'Arial']
Quill.register(Font, true)

const { TextArea, Search } = Input
const { RangePicker } = DatePicker
const { Option } = Select
const rangeConfig = {
	rules: [
		{
			type: 'array',
			required: true,
			message: 'Please select time!',
		},
	],
}

const UpdateContract = ({ match, history }) => {
	const contractID = match.params.id
	const [isUpdating, setIsUpdating] = useState(false)
	const [detailContract, setDetailContract] = useState({
		isLoading: true,
		data: {},
	})
	const [countryData, setCountryData] = useState([])
	const [fetching, setFetching] = useState(false)

	const fetchCountry = useCallback(
		debounce(async value => {
			if (!fetching) {
				setFetching(true)
				const res = await API.getCountryData({ keyword: value })
				setCountryData(res.data.list)
				setFetching(false)
			}
		}, 500)
	)

	useEffect(() => {
		fetchCountry('')
	}, [])

	// ========================================== Clause ============================
	const [isModalVisibleClauseGroup, setIsModalVisibleGroup] = useState(null)
	const [isModalVisibleClauseItem, setIsModalVisibleItem] = useState(null)

	const [keyword, setKeyword] = useState(null)
	const [pasal, setPasal] = useState({
		isLoading: true,
		items: [],
	})

	const onSearch = value => setKeyword(value.target.value)

	const fetchListPasal = () => {
		API.listClauseGroup({ keyword: keyword }).then(response => {
			if (response.code === 200) {
				setPasal({
					isLoading: false,
					items: response.data.list,
				})
			}
		})
	}

	useEffect(() => {
		fetchListPasal()
	}, [keyword])

	const modules = {
		toolbar: [
			[{ font: Font.whitelist }],
			['bold', 'italic', 'underline'],
			[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
		],
	}

	const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent']

	// ========================== Signer and Clause ============================================
	const [contractDataInput, setContractDataInput] = useState({
		type: null,
		name: null,
		location: null,
		contractStart: moment().format('YYYY-MM-DD'),
		contractEnd: moment().format('YYYY-MM-DD'),
	})

	const [clausesGroup, setClausesGroup] = useState([])

	// Pasal
	const addNewClauseGroup = () => {
		setClausesGroup(prevState => [
			...prevState,
			{
				ID: null,
				title: null,
				clausal: [
					{
						ID: null,
						clauseGroupID: null,
						content: '',
					},
				],
			},
		])
	}

	const removeClauseGroup = index => {
		const newData = clausesGroup.filter((data, dataIndex) => dataIndex !== index)

		setClausesGroup(newData)
	}

	// Ayat
	const addNewClause = clauseIndexGroup => {
		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clausal: [
				...clausesGroupItems[clauseIndexGroup].clausal,
				{
					ID: null,
					clauseGroupID: null,
					content: '',
				},
			],
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const removeClause = (clauseIndexGroup, index) => {
		const newData = clausesGroup[clauseIndexGroup].clausal.filter((data, dataIndex) => dataIndex !== index)

		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clausal: newData,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changePasal = (value, clauseIndexGroup) => {
		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			title: value,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changeClause = (value, clauseIndexGroup, index) => {
		let clausesGroupItemsClauses = [...clausesGroup[clauseIndexGroup].clausal]
		clausesGroupItemsClauses[index].content = value

		let clausesGroupItems = [...clausesGroup]
		let clause = {
			...clausesGroupItems[clauseIndexGroup],
			clausal: clausesGroupItemsClauses,
		}
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const changePasalExtendTemplate = (data, clauseIndexGroup) => {
		let clausesGroupItems = [...clausesGroup]
		let clause = data
		clausesGroupItems[clauseIndexGroup] = clause

		setClausesGroup(clausesGroupItems)
	}

	const [firstInstitusi, setFirstInstitusi] = useState({
		companyName: null,
		companyAddress: null,
		companyAbout: null,
		companyLogo: null,
		officerName: null,
		officerPosition: null,
		contractNumber: null,
	})

	const [lastInstitusi, setLastInstitusi] = useState({
		companyName: null,
		companyAddress: null,
		companyAbout: null,
		companyLogo: null,
		officerName: null,
		officerPosition: null,
		contractNumber: null,
		countryID: null,
		companyAbout: null,
	})

	const updateDataInputFirstInstitusi = parameters => {
		setFirstInstitusi(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	const updateDataInputLastInstitusi = parameters => {
		setLastInstitusi(prevState => ({
			...prevState,
			...parameters,
		}))
	}

	// Fetching Detail
	const fetchDetailContract = () => {
		API.contract.detail(contractID).then(response => {
			if (response.code === 200) {
				setContractDataInput({
					type: response.data.type,
					name: response.data.name,
					location: response.data.location,
					contractStart: response.data.contractStart,
					contractEnd: response.data.contractEnd,
				})

				setDetailContract({
					isLoading: false,
					data: response.data,
				})

				setClausesGroup(response.data.clauseGroups)

				if (response.data.contractSigners.length > 0) {
					setFirstInstitusi({
						companyName: response.data.contractSigners[0].companySigner.company.name,
						companyAddress: response.data.contractSigners[0].companySigner.company.address,
						companyAbout: response.data.contractSigners[0].companySigner.company.detail,
						companyLogo: response.data.contractSigners[0].companySigner.company.urlLogo,
						officerName: response.data.contractSigners[0].companySigner.officerName,
						officerPosition: response.data.contractSigners[0].companySigner.officerPosition,
						contractNumber: response.data.contractSigners[0].contractNumber,
					})

					if (response.data.contractSigners.length === 2) {
						setLastInstitusi({
							companyName: response.data.contractSigners[1].companySigner.company.name,
							companyAddress: response.data.contractSigners[1].companySigner.company.address,
							companyAbout: response.data.contractSigners[1].companySigner.company.detail,
							companyLogo: response.data.contractSigners[1].companySigner.company.urlLogo,
							officerName: response.data.contractSigners[1].companySigner.officerName,
							officerPosition: response.data.contractSigners[1].companySigner.officerPosition,
							contractNumber: response.data.contractSigners[1].contractNumber,
							countryID: response.data.contractSigners[1].companySigner.company.countryID,
							companyAbout: response.data.contractSigners[1].detail,
						})
					}
				}
			}
		})
	}

	useEffect(() => {
		fetchListPasal()
		fetchDetailContract()
	}, [])

	const [isSubmitingStepOne, setIsSubmitingStepOne] = useState(false)
	const [isSubmitingStepTwo, setIsSubmitingStepTwo] = useState(false)

	const submit = () => {
		// UPDATE CONTRACT AND SIGNER
		setIsSubmitingStepOne(true)
		API.contract.update(contractID, contractDataInput).then(response => {
			const responseDataContract = response

			if (response.code === 200) {
				if (response.data.contractSigners.length > 0) {
					// First
					API.assignSignerContractUpdate(response.data.contractSigners[0].ID, firstInstitusi).then(response => {
						if (response.code === 200) {
							// Last
							if (responseDataContract.data.contractSigners.length === 2) {
								API.assignSignerContractUpdate(responseDataContract.data.contractSigners[1].ID, lastInstitusi).then(
									response => {
										if (response.code === 200) {
											setIsSubmitingStepOne(false)
										} else {
											setIsSubmitingStepOne(false)
										}
									}
								)
							}
						} else {
							setIsSubmitingStepOne(false)
						}
					})
				}
			} else {
				message.info('Terjadi Kesalahan Pada Saat Buat Kesepakatan')
			}
		})

		// CLAUSES SECTION
		setIsSubmitingStepTwo(true)
		let allClausesGroup = clausesGroup
		// Title
		let titles = {}
		let titlesUpdate = {}
		let idsUpdate = {}
		// Clause
		let clauses = {}
		let clausesUpdate = {}
		let idsUpdateClauses = {}

		for (let index = 0; index < clausesGroup.length; index++) {
			if (allClausesGroup[index].ID === null) {
				titles = {
					...titles,
					...{ [`create[${index}][title]`]: allClausesGroup[index].title },
				}
			} else {
				titlesUpdate = {
					...titlesUpdate,
					...{
						[`updates[clauseGroup][${index}][title]`]: allClausesGroup[index].title,
					},
				}

				idsUpdate = {
					...idsUpdate,
					...{
						[`updates[clauseGroup][${index}][ID]`]: allClausesGroup[index].ID,
					},
				}
			}

			for (let clauseIndex = 0; clauseIndex < allClausesGroup[index].clausal.length; clauseIndex++) {
				if (
					allClausesGroup[index].clausal[clauseIndex].ID === '' ||
					allClausesGroup[index].clausal[clauseIndex].ID === null
				) {
					clauses = {
						...clauses,
						...{
							[`create[${index}][clause][${clauseIndex}]`]: allClausesGroup[index].clausal[clauseIndex].content,
						},
					}
				} else {
					clausesUpdate = {
						...clausesUpdate,
						...{
							[`updates[clause][${clauseIndex}][content]`]: allClausesGroup[index].clausal[clauseIndex].content,
						},
					}

					idsUpdateClauses = {
						...idsUpdateClauses,
						...{
							[`updates[clause][${clauseIndex}][ID]`]: allClausesGroup[index].clausal[clauseIndex].ID,
						},
					}
				}
			}
		}

		API.assignClauseContract(contractID, {
			...titles,
			...titlesUpdate,
			...idsUpdate,
			...clauses,
			...clausesUpdate,
			...idsUpdateClauses,
		}).then(response => {
			if (response.code === 200) {
				setIsSubmitingStepTwo(false)
			} else {
				message.error(response.message)
				setIsSubmitingStepTwo(false)
			}
		})

		setTimeout(() => {
			message.success('Kesepakatan Berhasil Di Update!')
			history.push('/contract')
		}, 1500)
	}

	const removeGroup = clauseGroupID => {
		let idGroup = {}

		idGroup = {
			...idGroup,
			...{ [`removes[clauseGroup][0]`]: clauseGroupID },
		}

		API.assignClauseContract(contractID, {
			...idGroup,
		}).then(response => {
			if (response.code === 200) {
				setIsSubmitingStepTwo(false)
			} else {
				message.error(response.message)
				setIsSubmitingStepTwo(false)
			}
		})
	}

	const removeGroupItem = clauseID => {
		let idGroup = {}

		idGroup = {
			...idGroup,
			...{ [`removes[clause][0]`]: clauseID },
		}

		API.assignClauseContract(contractID, {
			...idGroup,
		}).then(response => {
			if (response.code === 200) {
				setIsSubmitingStepTwo(false)
			} else {
				message.error(response.message)
				setIsSubmitingStepTwo(false)
			}
		})
	}

	return (
		<Layout>
			<div className='contract' style={{ marginBottom: 24 }}>
				<h3>Update Kesepakatan</h3>
			</div>
			<div style={{ background: '#fff', padding: 20 }}>
				<Row>
					{/* FORM CONTRACT */}
					<Col xs={24} md={8} lg={8} style={{ marginRight: 50 }}>
						<Form layout='vertical' size='small'>
							<Form.Item label='Jenis Document'>
								<Select
									style={{ width: 120 }}
									style={{ marginRight: 10 }}
									value={contractDataInput.type}
									onChange={value => {
										setContractDataInput(prevState => ({
											...prevState,
											type: value,
										}))
									}}>
									<Option value={null} disabled>
										--Pilih Jenis Document--
									</Option>
									<Option value={1}>MoU</Option>
									<Option value={2}>PKS</Option>
									<Option value={3}>IA</Option>
									<Option value={4}>MoA</Option>
									<Option value={5}>MoU & MoA</Option>
									<Option value={6}>Surat Pernyataan</Option>
								</Select>
							</Form.Item>

							<Form.Item label='Nama Perjanjian'>
								<Input
									placeholder='Nama Perjanjian'
									value={contractDataInput.name}
									onChange={({ target: { value } }) =>
										setContractDataInput(prevState => ({
											...prevState,
											name: value,
										}))
									}
								/>
							</Form.Item>

							<Form.Item label='Tempat Perjanjian'>
								<Input
									placeholder='Tempat'
									value={contractDataInput.location}
									onChange={({ target: { value } }) => {
										setContractDataInput(prevState => ({
											...prevState,
											location: value,
										}))
									}}
								/>
							</Form.Item>
							<Form.Item label='Tanggal Kerja Sama' {...rangeConfig}>
								<RangePicker
									style={{ width: '100%' }}
									value={[
										moment(contractDataInput.contractStart, 'YYYY-MM-DD'),
										moment(contractDataInput.contractEnd, 'YYYY-MM-DD'),
									]}
									onChange={date =>
										setContractDataInput(prevState => ({
											...prevState,
											contractStart: date[0].format('YYYY-MM-DD'),
											contractEnd: date[1].format('YYYY-MM-DD'),
										}))
									}
								/>
							</Form.Item>
							<Form.Item label='Institusi 1'>
								{firstInstitusi.companyName === 'Kementerian Pendidikan & Kebudayaan' ? (
									<>
										<Input
											placeholder='No Kesepakatan Institusi 1'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.contractNumber}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													contractNumber: value,
												})
											}}
										/>
										<p>Logo :</p>
										<input
											type='file'
											name='firstFile'
											onChange={({ target: { files } }) => {
												updateDataInputFirstInstitusi({
													companyLogo: files[0],
												})
											}}
										/>
										<span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
									</>
								) : (
									<>
										<Input
											placeholder='Nama Institusi 1'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.companyName}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													companyName: value,
												})
											}}
										/>
										<Input
											placeholder='No Kesepakatan Institusi 1'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.contractNumber}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													contractNumber: value,
												})
											}}
										/>
										<TextArea
											placeholder='Alamat'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.companyAddress}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													companyAddress: value,
												})
											}}></TextArea>
										<TextArea
											placeholder='About'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.companyAbout}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													companyAbout: value,
												})
											}}></TextArea>
										<Input
											placeholder='Officer Name / PIC'
											style={{ marginBottom: 10 }}
											value={firstInstitusi.officerName}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													officerName: value,
												})
											}}
										/>
										<Input
											placeholder='Jabatan '
											style={{ marginBottom: 10 }}
											value={firstInstitusi.officerPosition}
											onChange={({ target: { value } }) => {
												updateDataInputFirstInstitusi({
													officerPosition: value,
												})
											}}
										/>
										<p>Logo :</p>
										<input
											type='file'
											name='firstFile'
											onChange={({ target: { files } }) => {
												updateDataInputFirstInstitusi({
													companyLogo: files[0],
												})
											}}
										/>
										<span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
									</>
								)}
							</Form.Item>
							<Divider />
							<Form.Item label='Partner'>
								<Input
									placeholder='Nama Partner'
									style={{ marginBottom: 10 }}
									value={lastInstitusi.companyName}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											companyName: value,
										})
									}}
								/>
								<Input
									placeholder='No Kesepakatan Partner ( Optional )'
									style={{ marginBottom: 10 }}
									value={lastInstitusi.contractNumber}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											contractNumber: value,
										})
									}}
								/>
								<TextArea
									placeholder='Alamat'
									style={{ marginBottom: 10 }}
									value={lastInstitusi.companyAddress}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											companyAddress: value,
										})
									}}></TextArea>
								<Select
									showSearch
									value={lastInstitusi.countryID}
									placeholder='Pilih Negara'
									notFoundContent={fetching ? <Spin size='small' /> : null}
									filterOption={false}
									onSearch={fetchCountry}
									onChange={value => {
										setFetching(false)
										updateDataInputLastInstitusi({
											countryID: value,
										})
									}}
									style={{ marginBottom: 10 }}>
									{countryData.map(d => {
										return (
											<Option key={d.ID} value={d.ID}>
												{d.name}
											</Option>
										)
									})}
								</Select>
								<TextArea
									placeholder='About'
									style={{ marginBottom: 10 }}
									value={lastInstitusi.companyAbout}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											companyAbout: value,
										})
									}}></TextArea>
								<Input
									placeholder='Officer Name / PIC'
									style={{ marginBottom: 10 }}
									value={lastInstitusi.officerName}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											officerName: value,
										})
									}}
								/>
								<Input
									placeholder='Jabatan '
									style={{ marginBottom: 10 }}
									value={lastInstitusi.officerPosition}
									onChange={({ target: { value } }) => {
										updateDataInputLastInstitusi({
											officerPosition: value,
										})
									}}
								/>
								<p>Logo :</p>
								<input
									type='file'
									name='lastFile'
									onChange={({ target: { files } }) => {
										updateDataInputLastInstitusi({
											companyLogo: files[0],
										})
									}}
								/>
								<span style={{ fontSize: 12 }}>*Pastikan Ukurannnya 200 x 200</span>
							</Form.Item>
						</Form>
					</Col>

					{/* FORM CLAUSE */}
					<Col xs={24} md={14} lg={14}>
						<div
							style={{
								background: '#fafafa',
								padding: 10,
								width: '100%',
								borderRadius: 4,
							}}>
							<Form layout='vertical' size='small'>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'Mohon Di Inputkan!',
										},
									]}>
									{clausesGroup.map((clauseGroup, clauseGroupIndex) => {
										return (
											<>
												<p>Pasal {clauseGroupIndex + 1}</p>
												<div className='stepOneCreateContract'>
													<Input
														placeholder='Title Pasal'
														style={{ marginRight: 20 }}
														value={clauseGroup.title}
														onChange={({ target: { value } }) => {
															changePasal(value, clauseGroupIndex)
														}}
													/>
													{/* <Button
                            icon={<FolderAddOutlined />}
                            style={{ marginRight: 10 }}
                            onClick={addNewClauseGroup}
                          >
                            Tambah Pasal
                          </Button> */}
													<Button
														icon={<FileAddOutlined />}
														onClick={() => {
															setIsModalVisibleGroup(clauseGroupIndex)
														}}
														style={{ marginRight: 10 }}>
														Pilih Template
													</Button>
													<Button
														icon={<DeleteOutlined />}
														onClick={() => {
															removeClauseGroup(clauseGroupIndex)
															removeGroup(clauseGroup.ID)
														}}></Button>
												</div>
												<div className='stepOneCreateContractClause'>
													{clauseGroup.clausal.map((clause, clauseIndex) => {
														return (
															<>
																<div className='stepOneCreateContractClause__item'>
																	<ReactQuill
																		onChange={value => changeClause(value, clauseGroupIndex, clauseIndex)}
																		modules={modules}
																		formats={formats}
																		placeholder={'Ayat Pasal...'}
																		className='clause-container'
																		theme='snow'
																		value={clause.content}
																	/>
																	<Button
																		icon={<FolderAddOutlined />}
																		style={{ marginRight: 10 }}
																		onClick={() => addNewClause(clauseGroupIndex)}>
																		Tambah Ayat
																	</Button>
																	<Button
																		icon={<FileAddOutlined />}
																		onClick={() => {
																			setIsModalVisibleItem(clauseIndex)
																		}}
																		style={{ marginRight: 10 }}>
																		Pilih Template
																	</Button>

																	{clauseIndex !== 0 ? (
																		<Button
																			icon={<DeleteOutlined />}
																			onClick={() => {
																				removeClause(clauseGroupIndex, clauseIndex)
																				removeGroupItem(clause.ID)
																			}}></Button>
																	) : null}
																</div>
																<Divider />
																{/* ==================== Modal AYat================= */}
																<Modal
																	title={`Template Ayat - ${clauseGroup.title}`}
																	visible={isModalVisibleClauseItem === clauseIndex}
																	onCancel={() => setIsModalVisibleItem(null)}
																	footer={null}>
																	<Search
																		placeholder='Cari Berdasarkan Pasal atau Ayat'
																		// onSearch={onSearch}
																		onChange={onSearch}
																		style={{
																			width: '100%',
																			marginBottom: 14,
																		}}
																	/>

																	<div className='pasal'>
																		{pasal.isLoading ? (
																			<h5>Loading...</h5>
																		) : (
																			pasal.items.map(item => {
																				return (
																					<div>
																						<h5>{item.title}</h5>
																						<ul>
																							{item.templateClauses.map(ayat => {
																								return (
																									<li
																										onClick={() => {
																											changeClause(
																												ayat.content,
																												clauseGroupIndex,
																												isModalVisibleClauseItem
																											)
																											setIsModalVisibleItem(null)
																										}}>
																										<span
																											dangerouslySetInnerHTML={{
																												__html: `${ayat.content}`,
																											}}></span>
																									</li>
																								)
																							})}
																						</ul>
																					</div>
																				)
																			})
																		)}
																	</div>
																</Modal>
															</>
														)
													})}
												</div>
												<Divider />
												{/* ====================== PASAL ======================= */}
												<Modal
													title={'Template Pasal'}
													visible={isModalVisibleClauseGroup === clauseGroupIndex}
													onCancel={() => setIsModalVisibleGroup(null)}
													footer={null}>
													<Search
														placeholder='Cari Berdasarkan Pasal atau Ayat'
														// onSearch={onSearch}
														onChange={onSearch}
														style={{ width: '100%', marginBottom: 14 }}
													/>

													<div className='pasal'>
														{pasal.isLoading ? (
															<h5>Loading...</h5>
														) : (
															pasal.items.map((item, index) => {
																if (item.templateClauses.length === 0) {
																	return (
																		<div>
																			<h5
																				onClick={() => {
																					changePasal(item.title, isModalVisibleClauseGroup)
																					setTimeout(() => {
																						changePasalExtendTemplate(
																							{
																								ID: item.ID,
																								title: item.title,
																								clausal: [
																									{
																										ID: null,
																										clauseGroupID: null,
																										content: '',
																									},
																								],
																							},
																							isModalVisibleClauseGroup
																						)
																					}, 1000)
																					setIsModalVisibleGroup(null)
																				}}>
																				{item.title}
																			</h5>
																		</div>
																	)
																} else {
																	let clauseItems = {
																		ID: item.ID,
																		title: item.title,
																		clausal: [
																			{
																				ID: null,
																				clauseGroupID: null,
																				content: '',
																			},
																		],
																	}
																	return (
																		<div>
																			<h5
																				onClick={() => {
																					changePasal(item.title, isModalVisibleClauseGroup)
																					setTimeout(() => {
																						changePasalExtendTemplate(clauseItems, isModalVisibleClauseGroup)
																					}, 1000)

																					setIsModalVisibleGroup(null)
																				}}>
																				{item.title}
																			</h5>
																			<ul>
																				{item.templateClauses.map(ayat => {
																					clauseItems = {
																						...clauseItems,
																						clausal: [
																							...clauseItems.clausal,
																							{
																								ID: ayat.ID,
																								clauseGroupID: ayat.templateClauseGroupID,
																								content: ayat.content,
																							},
																						],
																					}
																					return (
																						<li>
																							<span
																								dangerouslySetInnerHTML={{
																									__html: `${ayat.content}`,
																								}}></span>
																						</li>
																					)
																				})}
																			</ul>
																		</div>
																	)
																}
															})
														)}
													</div>
												</Modal>
											</>
										)
									})}
									<EmptyState action={addNewClauseGroup} />
								</Form.Item>
							</Form>
						</div>
					</Col>
				</Row>

				{/* ACTION */}
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
					<Button
						style={{ marginRight: 20 }}
						type='default'
						onClick={() => history.goBack()}
					>
						Kembali
					</Button>
					<Button type='primary' onClick={() => submit()}>
						{isSubmitingStepOne || isSubmitingStepTwo ? 'Loading...' : 'Update'}
					</Button>
				</div>
			</div>
		</Layout>
	)
}

export default UpdateContract
