import React from 'react'
import 'antd/dist/antd.css'
import './Styles/main.scss'
import Routes from './routes'

import { BrowserRouter as Router } from 'react-router-dom'

import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

const App = () => {
	return (
		<Router>
			<Routes />
		</Router>
	)
}

export default App
