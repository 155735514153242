import decode from "jwt-decode";
import API from "./API";

const STORAGE_KEY = "authenticationToken";

export default class Authentication {
  static isLoggedIn() {
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  static isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  static login = (username, password) => {
    return API.login(username, password).then((response) => {
      if ((response.data ? response.data.token : null) || response.code === 200) {
        this.setToken(response.data.token);
        localStorage.setItem("email", response.data.account.email);
        localStorage.setItem(
          "contractOperatorAccess",
          response.data.account.contractOperatorAccess.ID
        );
        localStorage.setItem(
          "avatarURL",
          response.data.account.avatarURL
        );
      } else {
        return Promise.resolve(response);
      }
      return Promise.resolve(response);
    });
  };

  static setToken(idToken) {
    localStorage.setItem(STORAGE_KEY, idToken);
  }

  static getToken() {
    return localStorage.getItem(STORAGE_KEY);
  }

  static logout(history) {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem("email");
    localStorage.removeItem("contractOperatorAccess");
    localStorage.removeItem("avatarURL");
    history.push("/login");
  }

  static getProfile() {
    return {
      email: localStorage.getItem("email"),
      contractOperatorAccess: localStorage.getItem("contractOperatorAccess"),
    };
  }
}
