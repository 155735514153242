import React from 'react'
import { useState, useEffect } from 'react'
import SignatureInput from "../../Components/SignatureInput";

import { Spin } from 'antd'

import API from '../../Services/API'

import queryString from 'query-string'

const AssignWithToken = ({ history, match }) => {
	const parsed = queryString.parse(history.location.search)
	const signerToken = (parsed ? parsed.token : '')
	const [contractSigner, setContractSigner] = useState(null)
	const [checkSigner, setCheckSigner] = useState({ isVerified: false, checking: true })

	const checkingSigner = async () => {
		const response = await API.verifySigner(signerToken)
		if (response.code === 200) {
			setContractSigner(response.data.contractSigner)
			setCheckSigner({
				isVerified: true,
				checking: false,
				urlSigning: response.data.contractSigner.companySigner.signing.urlSigning
			})
		} else {
			setContractSigner(null)
			setCheckSigner({
				isVerified: false,
				checking: false,
				urlSigning: null
			})
		}
	}

	useEffect(() => checkingSigner(), [signerToken])

	return (
		<div className='site-layout-background' style={{ padding: 24, minHeight: 360 }}>
			{
				checkSigner.checking ?
					<div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Spin size='large' />
					</div> :
					!checkSigner.isVerified ?
						<div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<h3>Invalid Token</h3>
						</div> :
						<SignatureInput signer={contractSigner} token={signerToken} title="Form Penandatangan" onSuccess={(data) => {
							checkingSigner()
						}} />
			}
		</div>
	)
}

export default AssignWithToken
