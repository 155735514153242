import React from 'react'
import { Chart } from 'react-google-charts'

const BarChart = ({ data, title = '' }) => {
	return (
		<Chart
			height={'300px'}
			chartType='Bar'
			loader={<div>Loading Chart</div>}
			data={data}
			options={{
				// Material design options
				chart: {
					title,
				},
				backgroundColor: 'transparent',
				legend: {
					position: 'none',
				},
				vAxis: {
					format: '##',
				},
			}}
			// For tests
		/>
	)
}

export default BarChart
